import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { ApiCallStatus } from "../../utils/constants";
import { cloneDeep } from "lodash";
import { fetchAnnouncementsData } from "../PageFunctions/announcementFunctions";
import { Backdrop, CircularProgress } from "@mui/material";
import Announcements from "../../components/Announcements";
import { useAuth0 } from "@auth0/auth0-react";

const ModalName = Object.freeze({
	AnnouncementActivation: Symbol(1),
	AnnouncementDeactivation: Symbol(2)
});

const AnnouncementsPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [searchParams, setSearchParams] = useSearchParams();
	const [announcements, setAnnouncements] = useState([]);
	const [announcementToEdit, setAnnouncementToEdit] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);

	const [fetchAnnouncementsDataCallStatus, setFetchAnnouncementsDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoCreatePage = () => navigate("/announcement/create");
	const reloadPage = () => navigate(0);

	const fetchAnnouncementsDataAsync = async (pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchAnnouncementsData({
			auth0Token,
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			setAnnouncements,
			setTotalResults,
			setStatusInProgress: () => setFetchAnnouncementsDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchAnnouncementsDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchAnnouncementsDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchAnnouncementsDataCallStatus(ApiCallStatus.Error)
		});
	};

	const onPageNumberChange = async (_, {activePage}) => {
		await updateUrlQueryValue("pageNumber", activePage);
	};

	const updateUrlQueryValue = async (key, value) => {
		setFetchAnnouncementsDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/announcements',
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	const authorizedPageLoad = async () => {
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

		await Promise.all([
			fetchAnnouncementsDataAsync(pageNumberParam, pageSizeParam)
		]);
	};

	const openAnnouncementActivationModal = () => {
		setOpenModal(ModalName.AnnouncementActivation);
	};

	const openAnnouncementDeactivationModal = () => {
		setOpenModal(ModalName.AnnouncementDeactivation);
	};

	const closeModals = () => {
		setOpenModal(null);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		fetchAnnouncementsDataAsync(pageNumber);
	}, [pageNumber]);

	const allLoadingStates = [
		fetchAnnouncementsDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<Announcements
				announcements={announcements}
				totalResults={totalResults}
				pageNumber={pageNumber}
				pageSize={pageSize}
				onPageNumberChange={onPageNumberChange}
				onBackClick={gotoHomePage}
				showAnnouncementActivationModal={openModal === ModalName.AnnouncementActivation}
				showAnnouncementDeactivationModal={openModal === ModalName.AnnouncementDeactivation}
				openAnnouncementActivationModal={openAnnouncementActivationModal}
				openAnnouncementDeactivationModal={openAnnouncementDeactivationModal}
				closeModals={closeModals}
				announcementToEdit={announcementToEdit}
				setAnnouncementToEdit={setAnnouncementToEdit}
				gotoCreatePage={gotoCreatePage}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default AnnouncementsPage;