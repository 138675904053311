const prefix = process.env.REACT_APP_STORAGE_KEY_PREFIX || 'argus-';
export const storageKeys = {
	ACCESS_TOKEN: prefix + `${process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN || 'access_token'}`,
	ACCESS_TOKEN_EXPIRES_AT: prefix + `${process.env.REACT_APP_STORAGE_KEY_ACCESS_TOKEN_EXPIRES_AT || 'access_token_expires_at'}`,
	REFRESH_TOKEN: prefix + `${process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN || 'refresh_token'}`,
	REFRESH_EXPIRES_AT: prefix + `${process.env.REACT_APP_STORAGE_KEY_REFRESH_TOKEN_EXPIRES_AT || 'refresh_expires_at'}`,
	USERNAME: prefix + `${process.env.REACT_APP_STORAGE_KEY_EMAIL || 'email'}`,
	USER_ID: prefix + `${process.env.REACT_APP_STORAGE_KEY_USER_ID || 'user_id'}`,
	FIRSTNAME: prefix + `${process.env.REACT_APP_STORAGE_KEY_FIRST_NAME || 'first_name'}`,
	LASTNAME: prefix + `${process.env.REACT_APP_STORAGE_KEY_LAST_NAME || 'last_name'}`
};