import React, { useState } from 'react';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Controller, useForm } from "react-hook-form";
import { FormType } from "../../utils/constants";
import moment from "moment";
import {
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	IconButton,
	Box,
	Stack,
} from '@mui/material';
import { Save, ArrowBack, UploadFile } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toast } from "react-toastify";
import "./style.css";
import * as Papa from 'papaparse';

const PromotionCreateEditForm = ({
	formType,
	promotion,
	goBack,
	submit
}) => {

	const [selectedFile, setSelectedFile] = useState(null);
	const [parseError, setParseError] = useState(null);
	const [uploadErrors, setUploadErrors] = useState(null);
	const [uploadStatus, setUploadStatus] = useState(null);
	const [fileUploadLoading, setFileUploadLoading] = useState(false);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);

	const schema = yup.object({
		name: yup.string(),
		startDate: yup.string(),
		endDate: yup.string(),
		startTime: yup.string(),
		endTime: yup.string(),
		uploadFile: yup.mixed(),
	}).required();

	const {control, setValue, watch, getValues, handleSubmit, formState: {errors, isDirty}} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			name: (formType === FormType.Edit && !!promotion) ? promotion.name : "",
			startDate: (formType === FormType.Edit && !!promotion) ? moment(promotion.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
			endDate: (formType === FormType.Edit && !!promotion) ? moment(promotion.endDate).format("YYYY-MM-DD") : moment().add(7, 'd').format("YYYY-MM-DD"),
			startTime: (formType === FormType.Edit && !!promotion) ? moment(promotion.startDate).format("HH:mm") : moment().add(5, 'minutes').format("HH:mm"),
			endTime: (formType === FormType.Edit && !!promotion) ? moment(promotion.endDate).format("HH:mm") : moment().add(5, 'minutes').format("HH:mm")
		}
	});

	const onSubmit = async values => {
		const nowDate = moment();

		const startDateChanged = isDirty["startDate"];
		const startTimeChanged = isDirty["startTime"];

		const submittedStartDate = moment(`${values.startDate} ${values.startTime}`, "YYYY-MM-DD HH:mm", true);

		if (!submittedStartDate.isValid()) {
			toast('Start date/time format is invalid.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return;
		}

		if (formType === FormType.Create && submittedStartDate.isBefore(nowDate)) {
			toast('Start date/time is in the past.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});

			return;
		}

		if (submittedStartDate.isSameOrAfter(nowDate) && (startDateChanged || startTimeChanged)) {
			toast('Cannot change start date/time once promotion has started.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});

			return;
		}

		const submittedEndDate = moment(`${values.endDate} ${values.endTime}`, "YYYY-MM-DD HH:mm", true);

		if (!submittedEndDate.isValid()) {
			toast('End date/time format is invalid.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return;
		}

		if (submittedEndDate.isSameOrBefore(submittedStartDate)) {
			toast('End date/time must be after start date/time', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});

			return;
		}

		await submit(values);
	};

	const onCancelDeleteClick = () => {
		if (formType === FormType.Create) {
			goBack();
		}
		else {
			//deactivate
		}
	};

	const startDateTimeDisabled = () => {
		let disabled = false;
		if (!!promotion) {
			const startDateTime = moment(promotion.startDate);
			if (startDateTime.isSameOrBefore(moment())) {
				disabled = true;
			}
		}

		return disabled;
	};

	const setFileUploadModalOpen = (value) => {
		setUploadModalOpen(value);
	};

	return (
		<>
			<Paper elevation={3} sx={{ padding: '24px', maxWidth: 600, margin: 'auto', mt: 4 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
					<Typography variant="h6" color="primary">
						Create New Promotion
					</Typography>
					<IconButton color="primary" onClick={handleBackClick}>
						<ArrowBack />
					</IconButton>
				</Stack>

				<Grid container spacing={2}>
					{/* Promotion Name */}
					<Grid item xs={12}>
						<TextField
							label="Promotion Name"
							variant="outlined"
							fullWidth
							value={promotionName}
							onChange={(e) => setPromotionName(e.target.value)}
							required
						/>
					</Grid>

					{/* Start Date */}
					<Grid item xs={6}>
						<DesktopDatePicker
							label="Start Date"
							inputFormat="MM/dd/yyyy"
							value={startDate}
							onChange={(newDate) => setStartDate(newDate)}
							renderInput={(params) => <TextField {...params} fullWidth />}
						/>
					</Grid>

					{/* End Date */}
					<Grid item xs={6}>
						<DesktopDatePicker
							label="End Date"
							inputFormat="MM/dd/yyyy"
							value={endDate}
							onChange={(newDate) => setEndDate(newDate)}
							renderInput={(params) => <TextField {...params} fullWidth />}
						/>
					</Grid>

					{/* File Upload */}
					<Grid item xs={12}>
						<Button
							variant="outlined"
							component="label"
							startIcon={<UploadFile />}
							fullWidth
							sx={{ justifyContent: 'flex-start' }}
						>
							{file ? file.name : 'Upload Excel File'}
							<input
								type="file"
								hidden
								onChange={handleFileUpload}
								accept=".xlsx, .xls"
							/>
						</Button>
						{file && (
							<Typography variant="body2" color="textSecondary" mt={1}>
								Selected File: {file.name}
							</Typography>
						)}
					</Grid>

					{/* Save Button */}
					<Grid item xs={12}>
						<Box textAlign="center">
							<Button
								variant="contained"
								color="primary"
								startIcon={<Save />}
								onClick={handleSave}
								disabled={!promotionName || !startDate || !endDate || !file}
							>
								Save Promotion
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	);

};

export default PromotionCreateEditForm;