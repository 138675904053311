
import { toast } from "react-toastify";
import { sortBy } from "lodash";
import moment from "moment";
import {
	createPromotion, createPromotionProduct, deletePromotion, deletePromotionProduct, getAllPromotionProducts,
	getPromotion,
	getPromotionProducts, getPromotions,
	updatePromotion,
	updatePromotionProduct
} from "../../api/promotionController";

export const fetchPromotionsData = async ({
	auth0Token,
	pageSize,
	pageNumber,
	setPromotions,
	setPageNumber,
	setTotalResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getPromotions({
			auth0Token,
			pageSize,
			pageNumber
		});

		if (response?.success) {
			const total = response.total;
			const skip = response.skip;
			const limit = response.limit;

			setPromotions(response.data);
			setStatusSuccess();
			setPageNumber(skip + 1);
			setTotalResults(response.total);
		}
		else {
			setPromotions([]);
			setStatusFailed();
			setTotalResults(0);
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchAllPromotionProducts = async ({
	auth0Token,
	pageSize,
	pageNumber,
	setPromotionProducts,
	setPageNumber,
	setTotalResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getAllPromotionProducts({
			auth0Token,
			pageSize,
			pageNumber
		});

		if (response?.success) {
			const total = response.total;
			const skip = response.skip;
			const limit = response.limit;

			setPromotionProducts(response.data);
			setStatusSuccess();
			setPageNumber(skip + 1);
			setTotalResults(response.total);
		}
		else {
			setPromotions([]);
			setStatusFailed();
			setTotalResults(0);
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchPromotionData = async ({
	auth0Token,
	promotionId,
	setPromotionData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getPromotion({
			promotionId,
			auth0Token
		});

		if (response?.success) {
			setPromotionData(response.data);
			setStatusSuccess();
		}
		else {
			console.error(response.message);
			setStatusFailed();
			toast("Could not get promotion. Please try again later.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
	}
};

export const fetchPromotionProductsData = async ({
	auth0Token,
	promotionId,
	setPromotionProductsData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getPromotionProducts({
			promotionId,
			auth0Token
		});

		if (response?.success) {
			setPromotionProductsData(response.data);
			setStatusSuccess();
		}
		else {
			console.error(response.message);
			setStatusFailed();
			toast("Could not get promotion products. Please try again later.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
	}
};

export const createPromotionData = async ({
	auth0Token,
	name,
	startDate,
	endDate,
	products,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await createPromotion({
			auth0Token,
			name,
			startDate,
			endDate,
			products
		});

		if (response.success) {
			setStatusSuccess();
			toast("Promotion created.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
		else {
			setStatusFailed();
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not create promotion. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const createPromotionProductData = async ({
	auth0Token,
	promotionId,
	sku,
	retail1,
	retail2,
	retail3,
	retail4,
	retail5,
	retail6,
	retail10,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await createPromotionProduct({
			auth0Token,
			promotionId,
			sku,
			retail1,
			retail2,
			retail3,
			retail4,
			retail5,
			retail6,
			retail10
		});

		console.log(response);

		if (response.success) {
			setStatusSuccess();
			toast(`${sku} added to promotion.`, {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast(`Could not add ${sku} to promotion. Please try again later.`, {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const editPromotionData = async ({
	auth0Token,
	promotionId,
	name,
	startDate,
	endDate,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await updatePromotion({
			auth0Token,
			promotionId,
			name,
			startDate,
			endDate
		});

		if (response.success) {
			setStatusSuccess();
			toast("Promotion edited.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
		else {
			setStatusFailed();
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not edit promotion. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const editPromotionProductData = async ({
	auth0Token,
	promotionId,
	sku,
	retail1,
	retail2,
	retail3,
	retail4,
	retail5,
	retail6,
	retail10,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await updatePromotionProduct({
			auth0Token,
			promotionId,
			sku,
			retail1,
			retail2,
			retail3,
			retail4,
			retail5,
			retail6,
			retail10
		});

		if (response.success) {
			setStatusSuccess();
			toast("Promotion product edited.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not edit promotion product. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const deletePromotionData = async ({
	auth0Token,
	promotionId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError,
	setErrorMessage
}) => {
	setStatusInProgress();
	try {
		let response = await deletePromotion({
			auth0Token,
			promotionId
		});

		if (response.success) {
			setStatusSuccess();
			toast("Promotion deleted.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			setErrorMessage(response.message);
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not delete promotion. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const deletePromotionProductData = async ({
	auth0Token,
	sku,
	promotionId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError,
	setErrorMessage
}) => {
	setStatusInProgress();
	try {
		let response = await deletePromotionProduct({
			auth0Token,
			sku,
			promotionId
		});

		if (response.success) {
			setStatusSuccess();
			toast("Promotion product deleted.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			setErrorMessage(response.message);
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not delete promotion product. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};
