import React, {useState} from "react";
import moment from "moment";
import {
	Grid,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Pagination,
	Link,
	Popover,
	Menu,
	MenuItem, DialogTitle, DialogContent, DialogActions, Dialog, Divider
} from '@mui/material';
import { ArrowBack, AddCircle, Error, Delete, Refresh } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { PromotionsPageView } from "../../utils/constants";


const Promotions = ({
	onBackClick,
	onPageNumberChange,
	gotoCreatePage,
	pageNumber,
	pageSize,
	totalResults,
	promotions,
	reloadPage,
	handleDeleteClick,
	handleConfirmDeleteClick,
	promotionToDelete,
	setPromotionToDelete
}) => {

	const navigate = useNavigate();

	return (
		<div className="argus-min-height">
			<Typography variant="h5" mb={2}>Promotion Management</Typography>
			<Divider sx={{ mb: 3 }} />
			{!!promotions &&
				<Grid container spacing={2} sx={{ padding: '16px' }}>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<AddCircle />}
							onClick={gotoCreatePage}
							sx={{ float: 'right' }}
						>
							Create
						</Button>
					</Grid>

					{promotions.length > 0 &&
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell><Typography variant="subtitle2">Name</Typography></TableCell>
											<TableCell><Typography variant="subtitle2">Start Date</Typography></TableCell>
											<TableCell><Typography variant="subtitle2">End Date</Typography></TableCell>
											<TableCell><Typography variant="subtitle2"># of products</Typography></TableCell>
											<TableCell><Typography variant="subtitle2" align="center">Actions</Typography></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{promotions.map((promotion, index) => (
											<TableRow key={index} hover>
												<TableCell>
													<Link
														component="button"
														variant="body2"
														onClick={() => navigate(`/promotion/${promotion.promotionId}`)}
														underline="hover"
													>
														{promotion.name}
													</Link>
												</TableCell>
												<TableCell>{moment(promotion.startDate).format("DD-MM-YYYY")}</TableCell>
												<TableCell>{moment(promotion.endDate).format("DD-MM-YYYY")}</TableCell>
												<TableCell>{promotion.numberOfProducts}</TableCell>
												<TableCell align="center">
													<IconButton color="error" onClick={() => handleDeleteClick(promotion)}>
														<Delete size="small" />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					}

					{/* Pagination */}
					<Grid item xs={12} sx={{ textAlign: 'right', marginTop: '16px' }}>
						{totalResults > 0 && (
							<Pagination
								count={Math.ceil(totalResults / pageSize)}
								page={pageNumber}
								onChange={(e, page) => onPageNumberChange(page)}
								color="primary"
								variant="outlined"
								shape="rounded"
								size="small"
							/>
						)}
					</Grid>
				</Grid>
			}
			{!!promotionToDelete &&
				<Dialog open={!!promotionToDelete} onClose={() => setPromotionToDelete(null)}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						Are you sure you want to delete this promotion: <b>{promotionToDelete?.name}</b>?
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setPromotionToDelete(null)} color="primary">
							Cancel
						</Button>
						<Button onClick={handleConfirmDeleteClick} color="secondary">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			}

		</div>
	);
};

export default Promotions;