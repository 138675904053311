import React from 'react';
import {
	Box,
	Typography,
	Card,
	CardContent,
	Button,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	Grid,
	Divider,
	Stack,
	IconButton, Link
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SouthAfricanRand } from '../../utils/helpers';
import moment from 'moment';
import { OrderPaymentStatuses, OrderStatuses } from "../../utils/constants";

const UserCreateEditForm = ({
	user,
	orders,
	addresses,
	salesPeople,
	goBack,
	onLinkTecfinityAccountClick,
	onCreateTecfinityAccountClick,
	onUpdateTecfinityAccountClick
}) => {
	return (
		<Box sx={{ padding: 3 }}>
			<Typography variant="h5" mb={2}>User Details</Typography>
			<Divider sx={{ mb: 3 }} />

			<Grid container sx={{ mb: 3 }}>
				<Grid item xs={12} sx={{ textAlign: "right"}}>
					{!!user.tradeAccountsRequested?.length && !user.tradeAccounts.length &&
						<Button onClick={() => onLinkTecfinityAccountClick(user.tradeAccountsRequested[0])} variant="contained" color="primary">
							Link Commercial Account
						</Button>
					}
					{!user.tradeAccounts.length && !user.tradeAccountsRequested?.length &&
						<Button onClick={onCreateTecfinityAccountClick} variant="contained" color="primary">
							Convert to Commercial Account
						</Button>
					}
				</Grid>
			</Grid>

			<Divider sx={{ mb: 3 }} />

			<Grid container spacing={3}>
				{/* User Information */}
				<Grid item xs={12} md={6}>
					<Card variant="outlined" sx={{ height: '100%' }}>
						<CardContent>
							<Stack direction="row" alignItems="center" spacing={2} mb={2}>
								<PersonIcon color="primary" />
								<Typography variant="h6">User Information</Typography>
							</Stack>
							<Divider sx={{ mb: 2 }} />
							<Table size="small">
								<TableBody>
									<TableRow>
										<TableCell><strong>First Name:</strong></TableCell>
										<TableCell>{user.firstName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><strong>Last Name:</strong></TableCell>
										<TableCell>{user.lastName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><strong>Email:</strong></TableCell>
										<TableCell>{user.email}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><strong>Phone Number:</strong></TableCell>
										<TableCell>{user.phone}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</CardContent>
					</Card>
				</Grid>

				{/* Addresses */}
				<Grid item xs={12} md={6}>
					<Card variant="outlined" sx={{ height: '100%' }}>
						<CardContent>
							<Stack direction="row" alignItems="center" spacing={2} mb={2}>
								<HomeIcon color="primary" />
								<Typography variant="h6">Addresses</Typography>
							</Stack>
							<Divider sx={{ mb: 2 }} />
							{addresses?.length > 0 ? (
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Street Address</TableCell>
											<TableCell>City</TableCell>
											<TableCell>Province</TableCell>
											<TableCell>Country</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{addresses.map((address, index) => (
											<TableRow key={index}>
												<TableCell>{address.streetAddress}</TableCell>
												<TableCell>{address.city}</TableCell>
												<TableCell>{address.province?.name}</TableCell>
												<TableCell>{address.country?.name}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							) : (
								<Typography variant="body2" color="text.secondary">No addresses available</Typography>
							)}
						</CardContent>
					</Card>
				</Grid>

				{/* Trade Accounts */}
				<Grid item xs={12}>
					<Card variant="outlined" sx={{ height: '100%' }}>
						<CardContent>
							<Stack direction="row" alignItems="center" spacing={2} mb={2}>
								<AccountBalanceIcon color="primary" />
								<Typography variant="h6">Trade Accounts</Typography>
							</Stack>
							<Divider sx={{ mb: 2 }} />
							{user.tradeAccounts?.length > 0 ? (
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Account Number</TableCell>
											<TableCell>Name</TableCell>
											<TableCell>Telephone</TableCell>
											<TableCell>Email</TableCell>
											<TableCell>Category</TableCell>
											<TableCell>Actions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{user.tradeAccounts.map((account, index) => (
											<TableRow key={index}>
												<TableCell>{account.accountNumber}</TableCell>
												<TableCell>{account.name}</TableCell>
												<TableCell>{account.telephone}</TableCell>
												<TableCell>{account.email}</TableCell>
												<TableCell>{account.category}</TableCell>
												<TableCell>
													<Stack direction="row" alignItems="center" spacing={2} mb={2}>
														<IconButton size="small" onClick={() => onUpdateTecfinityAccountClick(account)}>
															<RefreshIcon fontSize="small" />
														</IconButton>
													</Stack>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							) : (
								<Typography variant="body2" color="text.secondary">No trade accounts available</Typography>
							)}
						</CardContent>
					</Card>
				</Grid>

				{/* Orders */}
				<Grid item xs={12}>
					<Card variant="outlined">
						<CardContent>
							<Stack direction="row" alignItems="center" spacing={2} mb={2}>
								<ShoppingCartIcon color="primary" />
								<Typography variant="h6">Orders</Typography>
							</Stack>
							<Divider sx={{ mb: 2 }} />
							{orders?.length > 0 ? (
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Order #</TableCell>
											<TableCell>Date</TableCell>
											<TableCell>Total</TableCell>
											<TableCell>Order Status</TableCell>
											<TableCell>Payment Status</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{orders.map((order, index) => (
											<TableRow key={index}>
												<TableCell>
													<Link href={`/order/${order.orderId}`}>
														{order.orderNumber}
													</Link>
												</TableCell>
												<TableCell>{moment(order.dateCreated).format('YYYY-MM-DD')}</TableCell>
												<TableCell>{SouthAfricanRand.format(order.total)}</TableCell>
												<TableCell>{OrderStatuses.find(status => status.value === order.orderStatusId)?.name}</TableCell>
												<TableCell>{OrderPaymentStatuses.find(status => status.value === order.orderPaymentStatusId)?.name}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							) : (
								<Typography variant="body2" color="text.secondary">No orders available</Typography>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export default UserCreateEditForm;
