import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
	Box,
	Modal,
	Typography,
	CircularProgress,
	Button,
	IconButton,
	Alert,
	Divider
} from "@mui/material";
import { CheckCircleOutline, Close } from '@mui/icons-material';
import ArgusLogo from "../../img/logo.svg";
import { updateStockInfo } from "../../main-component/PageFunctions/productFunctions";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: "90%",
	maxWidth: 500,
	bgcolor: '#FFFFFF',
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
	textAlign: 'center',
};

const modalStates = {
	confirm: 2,
	updating: 3,
	success: 4,
	error: 5
};

const UpdateStockInfoModal = ({
	open,
	setClose,
}) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [modalState, setModalState] = useState(modalStates.confirm);
	const [modalError, setModalError] = useState("");

	const updateStockInfoAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await updateStockInfo({
			auth0Token,
			setStatusInProgress: () => setModalState(modalStates.updating),
			setStatusSuccess: () => setModalState(modalStates.success),
			setStatusFailed: () => setModalState(modalStates.failed),
			setErrorMessage: () => setModalState(modalStates.error)
		});
	};

	const resetModal = () => {
		setModalError("");
		setModalState(modalStates.confirm);
	};

	const closeModal = () => {
		setModalState(modalStates.confirm);
		setModalError("");
		setClose();
	};

	return (
		<Modal open={open} onClose={closeModal}>
			<Box sx={style}>
				<IconButton onClick={closeModal} sx={{ position: 'absolute', top: 8, right: 8 }}>
					<Close />
				</IconButton>
				<img src={ArgusLogo} alt="Argus Logo" style={{ width: 80, marginBottom: 16 }} />
				<Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
					Update Stock Info
				</Typography>
				<Divider sx={{ mb: 2 }} />

				{(modalState === modalStates.updating) && (
					<CircularProgress />
				)}

				{modalState === modalStates.confirm && (
					<>
						<Typography variant="body1" sx={{ mb: 2 }}>
							Are you sure you want to update stock info?
						</Typography>

						<Button
							variant="contained"
							color="primary"
							onClick={updateStockInfoAsync}
							sx={{ mt: 3, width: "100%" }}
						>
							Confirm
						</Button>
						<Button variant="outlined"
						        color="error"
						        onClick={closeModal} sx={{ mt: 1, width: "100%" }}>
							Cancel
						</Button>
					</>
				)}

				{modalState === modalStates.success && (
					<>
						<CheckCircleOutline color="success" sx={{ fontSize: 48, mb: 2 }} />
						<Typography variant="h6">Success</Typography>
						<Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
							Stock info has been updated.
						</Typography>
						<Button variant="contained" color="primary" onClick={closeModal}>
							Close
						</Button>
					</>
				)}

				{modalState === modalStates.failed && (
					<>
						<CheckCircleOutline color="success" sx={{ fontSize: 48, mb: 2 }} />
						<Typography variant="h6">Failed</Typography>
						<Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
							Stock info could not be updated.
						</Typography>
						<Button variant="contained" color="primary" onClick={resetModal}>
							Try again
						</Button>
						<Button variant="outlined" color="error" onClick={closeModal}>
							Close
						</Button>
					</>
				)}

				{modalState === modalStates.error && modalError && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{modalError}
					</Alert>
				)}
			</Box>
		</Modal>
	);
};

export default UpdateStockInfoModal;
