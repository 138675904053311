import { getDetailedUser, getUsers, postTradeAccount, putTradeAccount } from "../../api/userController";
import { toast } from "react-toastify";
import { getCurrentUser } from "../../api/authenticationController";

export const fetchUsersData = async ({
	auth0Token,
	pageSize,
	pageNumber,
	name,
	email,
	sortColumn,
	sortDirection,
	setUsers,
	setPageNumber,
	setTotalResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const getUsersDataOptions = {
			auth0Token,
			pageSize,
			pageNumber,
			name,
			email,
			sortColumn,
			sortDirection
		};

		const response = await getUsers(getUsersDataOptions);
		if (!!response?.success) {
			const users = response.data.map(user => {
				user.tradeAccountRequested = user.tradeAccountsRequested.length > 0;
				user.isTradeAccount = user.tradeAccounts.length > 0;

				return user;
			});

			setUsers(users);
			setPageNumber(response.skip + 1);
			setTotalResults(response.total);
			setStatusSuccess();
		}
		else {
			setUsers([]);
			setTotalResults(0);
			toast("Could not get users.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			setStatusFailed();
		}

	}
	catch (error) {
		console.error(error);
		toast("Could not get users.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
		setStatusError();
	}
};

export const fetchDetailedUserData = async ({
	auth0Token,
	userId,
	setUser,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getDetailedUser({userId, auth0Token});

		if (!!response?.success) {
			setUser(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get user.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get user.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchCurrentUserData = async ({
	auth0Token,
	setCurrentUser,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError,
	setLoggedInTrue,
	setLoggedInFalse
}) => {
	setStatusInProgress();
	try {
		const response = await getCurrentUser({auth0Token});
		if (response.success) {
			setCurrentUser(response.data);
			setStatusSuccess();
			setLoggedInTrue();
		}
		else {
			setStatusFailed();
			setLoggedInFalse();
		}
	}
	catch (e) {
		console.error(e);
		setLoggedInFalse();
		setStatusError();
	}
};

export const editTradeAccount = async ({
	auth0Token,
	userId,
	websiteId,
	tradeAccountId,
	accountNumber,
	companyRegNo,
	personalIdNumber,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();

	try {
		const response = await putTradeAccount({
			auth0Token,
			userId,
			websiteId,
			tradeAccountId,
			accountNumber,
			companyRegNo,
			personalIdNumber
		});

		if (!!response.success) {
			setStatusSuccess();
			//;
		}
		else {
			setStatusFailed();
			//;
		}
	}
	catch (error) {
		setStatusError();
		//;
		console.error(error);
	}
};

export const createTradeAccount = async ({
	auth0Token,
	userId,
	accountNumber,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();

	try {
		const response = await postTradeAccount({
			auth0Token,
			userId,
			accountNumber
		});

		if (!!response.success) {
			setStatusSuccess();
			//;
		}
		else {
			setStatusFailed();
			//;
		}
	}
	catch (error) {
		setStatusError();
		//;
		console.error(error);
	}
};