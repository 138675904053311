import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { Button, Grid, Header, Icon, Image } from "semantic-ui-react";
import ArgusLogo from "../../img/logo.svg";
import { AnnouncementType } from "../../utils/constants";
import moment from "moment";
import { deactivateAnnouncementData } from "../../main-component/PageFunctions/announcementFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minHeight: '50%',
	width: "25%",
	bgcolor: '#FFFFFF',
	boxShadow: 24,
	p: 4
};

const ModalState = Object.freeze({
	confirm: Symbol(1),
	deactivating: Symbol(2),
	deactivated: Symbol(3),
	notDeactivated: Symbol(4),
	error: Symbol(5)
});

const AnnouncementDeactivationModal = ({
	open,
	setOpen,
	setClose,
	announcement
}) => {
	const { isAuthenticated, getAccessTokenSilently} = useAuth0();
	const [currentModalState, setCurrentModalState] = useState(ModalState.confirm);
	const [modalError, setModalError] = useState("");

	useEffect(() => {
		if (!announcement) {
			setCurrentModalState(ModalState.error);
			setModalError("Could not load announcement information. Please try again later or contact support.");
		}
	}, []);

	const handleConfirm = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await deactivateAnnouncementData({
			auth0Token,
			announcementId: announcement.announcementId,
			setStatusInProgress: () => setCurrentModalState(ModalState.deactivating),
			setStatusSuccess: () => setCurrentModalState(ModalState.deactivated),
			setStatusFailed: () => setCurrentModalState(ModalState.notDeactivated),
			setStatusError: () => setCurrentModalState(ModalState.error),
			setErrorMessage: (error) => setModalError(error)
		});
	};

	const closeModal = () => {
		setClose();
	};

	return (
		<Modal open={open}>
			<Box sx={style}>
				<Grid>
					<Grid.Row textAlign="center">
						<Grid.Column width={16} textAlign="center">
							<Image src={ArgusLogo} size="small" fluid centered/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row verticalAlign="middle" textAlign="center">
						<Grid.Column width={16}>
							<div className="argus-modal-title">
								{currentModalState === ModalState.confirm && "Deactivate announcement"}
								{currentModalState === ModalState.deactivating && "Deactivating announcement"}
								{currentModalState === ModalState.deactivated && "Announcement deactivated"}
								{currentModalState === ModalState.notDeactivated && "Announcement deactivation failed"}
							</div>
						</Grid.Column>
					</Grid.Row>
					{(currentModalState === ModalState.confirm) && !!announcement &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<div className="argus-modal-text">
										Are you sure you want to deactivate this announcement?
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle">
								<Grid.Column width={16}>
									{!!announcement.title &&
										<p><b>Title: </b> &nbsp; {announcement.title}</p>
									}
									{!!announcement.type &&
										<p>
											<b>Type: </b> &nbsp; {Object.keys(AnnouncementType).find(key => AnnouncementType[key] === announcement.type)}
										</p>
									}
									{!!announcement.startDate &&
										<p><b>Start
											date: </b> &nbsp; {moment(announcement.startDate).format("YYYY-MM-DD")}</p>
									}
									{!!announcement.startDate &&
										<p><b>Start time: </b> &nbsp; {moment(announcement.startDate).format("HH:mm")}
										</p>
									}
									{!!announcement.endDate &&
										<p><b>End date: </b> &nbsp; {moment(announcement.endDate).format("YYYY-MM-DD")}
										</p>
									}
									{!!announcement.endDate &&
										<p><b>End time: </b> &nbsp; {moment(announcement.endDate).format("HH:mm")}</p>
									}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        className="blue-button"
									        floated="right"
									        onClick={handleConfirm}>
										Confirm
									</Button>
									<Button type="button"
									        floated="right"
									        onClick={closeModal}>
										Cancel
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{(currentModalState === ModalState.deactivating) && !!announcement &&
						<>
							<Grid.Row>
								<Grid.Column width={16}>
									<div className="argus-modal-text">
										Deactivating. Please wait...
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        className="blue-button"
									        disabled
									        floated="right"
									        onClick={handleConfirm}>
										Confirm
									</Button>
									<Button type="button"
									        className="blue-button"
									        disabled
									        floated="right"
									        onClick={closeModal}>
										Cancel
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === ModalState.deactivated &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element"
									      name="check circle outline"
									      color="green"
									      size="massive"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16} textAlign="center">
									<div className="argus-modal-text">
										<p>The announcement will no longer be shown to users.</p>
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        floated="right"
									        onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === ModalState.notDeactivated &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element" name="times circle outline" color="red"
									      size="big"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Announcement not deactivated</Header>
								</Grid.Column>
								<Grid.Column width={16}>
									<Header as="h3" textAlign="center">
										{`Could not deactivate announcement: ${announcement.title}`}
									</Header>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        floated="right"
									        onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === ModalState.error && !!modalError &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element" name="ban" color="red" size="massive"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Error</Header>
								</Grid.Column>
								<Grid.Column width={16} textAlign="center">
									<p className="b1">{modalError}</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        floated="right"
									        onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
				</Grid>
			</Box>
		</Modal>
	);
};

export default AnnouncementDeactivationModal;