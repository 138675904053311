import React, { useState } from 'react';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Controller, useForm } from "react-hook-form";
import { AnnouncementType, FormType } from "../../utils/constants";
import moment from "moment";
import { Button, Form, Grid, Icon, Segment } from "semantic-ui-react";
import { ErrorMessage } from "@hookform/error-message";
import AnnouncementModal from "../../components/AnnouncementModal";
import { toast } from "react-toastify";
import "./style.css";

const AnnouncementCreateEditForm = ({
	formType,
	announcement,
	goBack,
	submit
}) => {

	const [showPreview, setShowPreview] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);
	const [previewType, setPreviewType] = useState(null);

	const schema = yup.object({
		websiteId: yup.string(),
		title: yup.string(),
		bannerImage: yup.string(),
		type: yup.number(),
		startDate: yup.string(),
		endDate: yup.string(),
		startTime: yup.string(),
		endTime: yup.string(),
		showForever: yup.boolean()
	}).required();

	const {control, watch, getValues, handleSubmit, formState: {errors, isDirty}} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			websiteId: (formType === FormType.Edit && !!announcement) ? announcement.websiteId : process.env.REACT_APP_PUBLIC_WEBSITE_ID,
			title: (formType === FormType.Edit && !!announcement) ? announcement.title : "",
			html: "",
			fullImage: (formType === FormType.Edit && !!announcement) ? announcement.fullImage : true,
			bannerImage: (formType === FormType.Edit && !!announcement) ? announcement.bannerImage : "",
			type: (formType === FormType.Edit && !!announcement) ? announcement.type : null,
			startDate: (formType === FormType.Edit && !!announcement) ? moment(announcement.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
			endDate: (formType === FormType.Edit && !!announcement) ? moment(announcement.endDate).format("YYYY-MM-DD") : moment().add(7, 'd').format("YYYY-MM-DD"),
			startTime: (formType === FormType.Edit && !!announcement) ? moment(announcement.startDate).format("HH:mm") : moment().add(5, 'minutes').format("HH:mm"),
			endTime: (formType === FormType.Edit && !!announcement) ? moment(announcement.endDate).format("HH:mm") : moment().add(5, 'minutes').format("HH:mm"),
			showForever: (formType === FormType.Edit && !!announcement && !announcement.endDate)
		}
	});

	const showForever = watch("showForever");

	const announcementTypeOptions = Object.entries(AnnouncementType).map(([key, value]) => ({
		key: value,
		text: key,
		value: value
	}));

	const showForeverOptions = [
		{
			key: 1,
			text: "No",
			value: false
		},
		{
			key: 2,
			text: "Yes",
			value: true
		}
	];

	const onSubmit = async values => {
		const nowDate = moment();

		const startDateChanged = isDirty["startDate"];
		const startTimeChanged = isDirty["startTime"];

		const submittedStartDate = moment(`${values.startDate} ${values.startTime}`, "YYYY-MM-DD HH:mm", true);

		if (!submittedStartDate.isValid()) {
			toast('Start date/time format is invalid.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return;
		}

		if (formType === FormType.Create && submittedStartDate.isBefore(nowDate)) {
			toast('Start date/time is in the past.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});

			return;
		}

		if (submittedStartDate.isSameOrAfter(nowDate) && (startDateChanged || startTimeChanged)) {
			toast('Cannot change start date/time once announcement has started.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});

			return;
		}

		if (!values.showForever) {
			const submittedEndDate = moment(`${values.endDate} ${values.endTime}`, "YYYY-MM-DD HH:mm", true);

			if (!submittedEndDate.isValid()) {
				toast('End date/time format is invalid.', {
					type: "error",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
				return;
			}

			if (submittedEndDate.isSameOrBefore(submittedStartDate)) {
				toast('End date/time must be after start date/time', {
					type: "error",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});

				return;
			}
		}

		await submit(values);
	};

	const onCancelDeleteClick = () => {
		if (formType === FormType.Create) {
			goBack();
		}
		else {
			//deactivate
		}
	};

	const onShowPreviewClick = () => {
		const type = getValues("type");
		const image = getValues("bannerImage");

		if (!image || !type) {
			toast('Preview unavailable. Announcement not complete.', {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
		else {
			setPreviewImage(image);
			setPreviewType(type);
			setShowPreview(true);
		}
	};

	const closePreview = () => {
		setPreviewImage("");
		setPreviewType(null);
		setShowPreview(false);
	};

	const startDateTimeDisabled = () => {
		let disabled = false;
		if (!!announcement) {
			const startDateTime = moment(announcement.startDate);
			if (startDateTime.isSameOrBefore(moment())) {
				disabled = true;
			}
		}

		return disabled;
	};


	return (
		<>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16} verticalAlign="middle">
							<Button floated="left"
							        size="tiny"
							        type="button"
							        className="blue-basic-button borderless"
							        onClick={goBack}>
								<Icon name="arrow left"/>
								Back
							</Button>
							<Button
								className="red-button"
								type="button"
								floated="right"
								onClick={onCancelDeleteClick}>
								<Icon name="trash"/>
								{formType === FormType.Create ? "Cancel" : "Deactivate"}
							</Button>
							<Button
								className="blue-button"
								type="submit"
								floated="right">
								<Icon name="save"/>
								{formType === FormType.Create ? "Create" : "Save"}
							</Button>
							<Button
								className="green-button"
								type="button"
								floated="right"
								onClick={onShowPreviewClick}>
								<Icon name="eye"/>
								Preview
							</Button>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Segment padded>
							<Grid container>
								<Grid.Row>
									<Grid.Column width={16}>
										<Controller
											name="title"
											control={control}
											render={({field: {onChange, value}}) => (
												<Form.Input
													label="Title"
													onChange={onChange}
													value={value}
													disabled={formType === FormType.Edit}
													placeholder="Choose a title for the announcement..."/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="title"
											render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<Controller
											name="bannerImage"
											control={control}
											render={({field: {onChange, value}}) => (
												<Form.Input
													label="Image"
													onChange={onChange}
													value={value}
													placeholder="Paste the image URL..."/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="bannerImage"
											render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										{formType === FormType.Create &&
											<Controller
												name="type"
												control={control}
												render={({field: {onChange, value, ...field}}) => (
													<Form.Select
														clearable
														label="Announcement type"
														options={announcementTypeOptions}
														value={value}
														placeholder="Choose an announcement type"
														onChange={(e, data) => onChange(data.value)}
													/>
												)}
											/>
										}
										{formType === FormType.Edit &&
											<Form.Input
												label="Announcement type"
												value={Object.keys(AnnouncementType).find(key => AnnouncementType[key] === announcement.type)}
												disabled
												placeholder="Choose a title for the announcement..."/>
										}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<Controller
											name="showForever"
											control={control}
											render={({field: {onChange, value, ...field}}) => (
												<Form.Select
													label="Show forever"
													options={showForeverOptions}
													value={value}
													onChange={(e, data) => onChange(data.value)}
												/>
											)}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<Controller
											name="startDate"
											control={control}
											render={({field: {onChange, value}}) => (
												<Form.Input
													label="Start date (YYYY-MM-DD)"
													onChange={onChange}
													disabled={startDateTimeDisabled()}
													value={value}
													placeholder="Start date - format: YYYY/MM/DD"/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="startDate"
											render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
										/>
									</Grid.Column>
									<Grid.Column width={8}>
										<Controller
											name="endDate"
											control={control}
											render={({field: {onChange, value}}) => (
												<Form.Input
													label="End date (YYYY-MM-DD)"
													onChange={onChange}
													value={value}
													disabled={!!showForever}
													placeholder="End date"/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="endDate"
											render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<Controller
											name="startTime"
											control={control}
											render={({field: {onChange, value}}) => (
												<Form.Input
													label="Start time (HH:mm)"
													onChange={onChange}
													disabled={startDateTimeDisabled()}
													value={value}
													placeholder="Start time"/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="startTime"
											render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
										/>
									</Grid.Column>
									<Grid.Column width={8}>
										<Controller
											name="endTime"
											control={control}
											render={({field: {onChange, value}}) => (
												<Form.Input
													label="End time (HH:mm)"
													onChange={onChange}
													disabled={!!showForever}
													value={value}
													placeholder="End time"/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="endTime"
											render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Row>
				</Grid>
			</Form>
			{!!showPreview &&
				<AnnouncementModal image={previewImage}
				                   type={previewType}
				                   open={showPreview}
				                   setClose={closePreview}/>
			}
		</>
	);

};

export default AnnouncementCreateEditForm;