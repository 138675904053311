import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiCallStatus, FormType } from "../../utils/constants";
import { Backdrop, CircularProgress } from "@mui/material";
import AnnouncementCreateEdit from "../../components/AnnouncementCreateEdit";
import { createAnnouncementData } from "../PageFunctions/announcementFunctions";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";

const AnnouncementCreatePage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [createAnnouncementDataCallStatus, setCreateAnnouncementDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoAnnouncementsPage = () => navigate("/announcements");
	const reloadPage = () => navigate(0);

	const createAnnouncementDataAsync = async ({
		title,
		html,
		bannerImage,
		startDate,
		endDate,
		type
	}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createAnnouncementData({
			auth0Token,
			title,
			html,
			bannerImage,
			startDate,
			endDate,
			type,
			setStatusInProgress: () => setCreateAnnouncementDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCreateAnnouncementDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCreateAnnouncementDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreateAnnouncementDataCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {
		await Promise.all([

		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		if (createAnnouncementDataCallStatus === ApiCallStatus.Succeeded) {
			gotoAnnouncementsPage();
		}
	}, [createAnnouncementDataCallStatus]);

	const onSubmit = async (values) => {
		const timezoneOffset = new Date().getTimezoneOffset();
		const offsetHours = parseInt(Math.abs(timezoneOffset / 60));
		const offsetMinutes = Math.abs(timezoneOffset % 60);
		const offsetSign = timezoneOffset > 0 ? "-" : "+";
		const formattedOffset = offsetSign + String(offsetHours).padStart(2, '0') + ":" + String(offsetMinutes).padStart(2, '0');

		const startDateInput = moment(`${values.startDate} ${values.startTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;
		const endDateInput = moment(`${values.endDate} ${values.endTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;

		await createAnnouncementDataAsync({
			title: values.title,
			html: "",
			bannerImage: values.bannerImage,
			startDate: startDateInput,
			endDate: endDateInput,
			type: values.type
		});
	};

	const allLoadingStates = [
		createAnnouncementDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<AnnouncementCreateEdit
				formType={FormType.Create}
				onBackClick={gotoAnnouncementsPage}
				submit={onSubmit}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default AnnouncementCreatePage;