import axios from "axios";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_IDP_URL;

export const postLinkTecfinityAccount = async ({postLinkTecfinityAccountOptions, auth0Token}) => {

	try {
		const token = "";

		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/user/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			userId: postLinkTecfinityAccountOptions.userId,
			tecfinityAccountNumber: postLinkTecfinityAccountOptions.tecfinityAccountNumber
		};

		const requestUrl = `link-tecfinity`;

		return await instance.post(requestUrl, body)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const deleteLinkTecfinityAccount = async ({
	auth0Token,
	userId,
	tecfinityAccountNumber
}) => {

	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/user/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}
		const body = {
			tecfinityAccountNumber
		};
		const response = await instance.put(`delink-tecfinity/${userId}`, body);

		return response?.data ?? null;
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const postActivateUserAccount = async ({userId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/user/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `activate/${userId}`;

		return await instance.put(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getUsers = async ({
	pageSize,
	pageNumber,
	auth0Token,
	name,
	email,
	sortColumn,
	sortDirection
}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/user`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}
		const skip = !!pageNumber ? Number(pageNumber) - 1 : 0;
		const searchParams = new URLSearchParams();
		searchParams.append("limit", pageSize);
		searchParams.append("skip", skip);
		if (!!name) searchParams.append("name", name);
		if (!!email) searchParams.append("email", email);
		if (!!sortColumn) searchParams.append("sortColumn", sortColumn);
		if (!!sortDirection) searchParams.append("sortDirection", sortDirection);

		const requestUrl = `?${searchParams.toString()}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total,
				                     skip: response.data.skip
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getUser = async ({userId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/user/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `${userId}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getDetailedUser = async ({userId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/user/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `detailed/${userId}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const putTradeAccount = async ({
	auth0Token,
	userId,
	websiteId,
	tradeAccountId,
	accountNumber,
	companyRegNo,
	personalIdNumber
}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/user/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			userId,
			websiteId,
			accountNumber,
			companyRegNo,
			personalIdNumber
		};

		const requestUrl = `tecfinity/${tradeAccountId}`;

		return await instance.put(requestUrl, body)
		                     .then(response => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const postTradeAccount = async ({
	auth0Token,
	userId,
	accountNumber
}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/tradeAccount/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			accountNumber
		};

		const requestUrl = `${userId}`;

		return await instance.post(requestUrl, body)
		                     .then(response => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};