import React from 'react';
import {
	Box,
	Grid,
	Paper,
	Typography,
	Button,
	Card,
	CardContent,
	Divider,
	Stack,
	List,
	ListItem,
	ListItemText,
	Container
} from '@mui/material';
import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LockIcon from '@mui/icons-material/Lock';
import { useAuth0 } from "@auth0/auth0-react";

const Home = ({
	loading,
	pageContent
}) => {
	const {isAuthenticated, loginWithRedirect} = useAuth0();
	return (
		<>
			{isAuthenticated === false &&
				<Box sx={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center', bgcolor: '#f5f5f5' }}>
					<Container maxWidth="xs">
						<Stack spacing={4} alignItems="center" textAlign="center">
							<LockIcon fontSize="large" color="primary" />
							<Typography variant="h5" fontWeight="bold">
								Admin Portal
							</Typography>
							<Typography variant="body1" color="textSecondary">
								Please log in to access the admin features.
							</Typography>
							<Button onClick={loginWithRedirect} to="/login" variant="contained" color="primary" size="large">
								Log In
							</Button>
						</Stack>
					</Container>
				</Box>
			}
			{isAuthenticated === true &&
				<Box sx={{ padding: 4 }}>
					<Typography variant="h4" gutterBottom>
						Admin Dashboard
					</Typography>

					{/* Overview Statistics */}
					<Grid container spacing={3} mb={4}>
						<Grid item xs={12} md={6} lg={3}>
							<Paper sx={{ p: 3, textAlign: 'center' }}>
								<Typography variant="h6" color="primary">
									Orders
								</Typography>
								{pageContent?.numberOfOrders &&
									<>
										<ShoppingCartIcon fontSize="large" color="action" />
										<Typography variant="h4">{pageContent.numberOfOrders}</Typography>
										<Typography variant="subtitle2">Orders Processed</Typography>
									</>
								}
								<Button component={Link} to="/orders" variant="contained" color="primary" size="small" sx={{ mt: 2 }}>
									Manage Orders
								</Button>
							</Paper>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<Paper sx={{ p: 3, textAlign: 'center' }}>
								<Typography variant="h6" color="primary">
									Users
								</Typography>

								{pageContent?.numberOfUsers &&
									<>
										<PeopleIcon fontSize="large" color="action" />
										<Typography variant="h4">{pageContent.numberOfUsers}</Typography>
										<Typography variant="subtitle2">Active Users</Typography>
									</>
								}
								<Button component={Link} to="/users" variant="contained" color="primary" size="small" sx={{ mt: 2 }}>
									Manage Users
								</Button>
							</Paper>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<Paper sx={{ p: 3, textAlign: 'center' }}>
								<Typography variant="h6" color="primary">
									Products
								</Typography>

								{pageContent?.numberOfProducts &&
									<>
										<LocalOfferIcon fontSize="large" color="action" />
										<Typography variant="h4">{pageContent.numberOfProducts}</Typography>
										<Typography variant="subtitle2">Available Products</Typography>
									</>
								}
								<Button component={Link} to="/products" variant="contained" color="primary" size="small" sx={{ mt: 2 }}>
									Manage Products
								</Button>
							</Paper>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<Paper sx={{ p: 3, textAlign: 'center' }}>
								<Typography variant="h6" color="primary">
									Announcements
								</Typography>
								{pageContent?.numberOfAnnouncements &&
									<>
										<AnnouncementIcon fontSize="large" color="action" />
										<Typography variant="h4">{pageContent.numberOfAnnouncements}</Typography>
										<Typography variant="subtitle2">Active Announcements</Typography>
									</>
								}
								<Button component={Link} to="/announcements" variant="contained" color="primary" size="small" sx={{ mt: 2 }}>
									Manage Announcements
								</Button>
							</Paper>
						</Grid>
					</Grid>

					{/* Quick Links and Recent Activities */}
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Card variant="outlined">
								<CardContent>
									<Typography variant="h6" gutterBottom>
										Quick Links
									</Typography>
									<Stack direction="row" spacing={2} mt={2}>
										<Button component={Link} to="/promotion/create" variant="contained">
											New Promotion
										</Button>
										<Button component={Link} to="/announcement/create" variant="contained">
											New Announcement
										</Button>
									</Stack>
								</CardContent>
							</Card>
						</Grid>

						{/*<Grid item xs={12} md={6}>*/}
						{/*	<Card variant="outlined">*/}
						{/*		<CardContent>*/}
						{/*			<Typography variant="h6" gutterBottom>*/}
						{/*				Recent Activities*/}
						{/*			</Typography>*/}
						{/*			<List>*/}
						{/*				<ListItem>*/}
						{/*					<ListItemText primary="Order #1234 processed" secondary="2 hours ago" />*/}
						{/*				</ListItem>*/}
						{/*				<Divider />*/}
						{/*				<ListItem>*/}
						{/*					<ListItemText primary="New user 'John Doe' added" secondary="4 hours ago" />*/}
						{/*				</ListItem>*/}
						{/*				<Divider />*/}
						{/*				<ListItem>*/}
						{/*					<ListItemText primary="Product 'Widget Pro' updated" secondary="6 hours ago" />*/}
						{/*				</ListItem>*/}
						{/*				<Divider />*/}
						{/*				<ListItem>*/}
						{/*					<ListItemText primary="Announcement 'Holiday Sale' posted" secondary="1 day ago" />*/}
						{/*				</ListItem>*/}
						{/*			</List>*/}
						{/*		</CardContent>*/}
						{/*	</Card>*/}
						{/*</Grid>*/}
					</Grid>

					{/* Footer Links */}
					<Grid container spacing={2} mt={5} justifyContent="center">
						<Grid item>
							<Button component={Link} to="/orders" color="primary">
								View All Orders
							</Button>
						</Grid>
						<Grid item>
							<Button component={Link} to="/users" color="primary">
								View All Users
							</Button>
						</Grid>
						<Grid item>
							<Button component={Link} to="/products" color="primary">
								View All Products
							</Button>
						</Grid>
						<Grid item>
							<Button component={Link} to="/announcements" color="primary">
								View All Announcements
							</Button>
						</Grid>
					</Grid>
				</Box>
			}
		</>
	);
};
export default Home;