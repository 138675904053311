import React, { Fragment, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import { cloneDeep } from "lodash";
import {
	deletePromotionData,
	fetchPromotionsData
} from "../PageFunctions/promotionFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import Promotions from "../../components/Promotions";

const PromotionsPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const [promotions, setPromotions] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);
	const [promotionToDelete, setPromotionToDelete] = useState(null);

	const [fetchPromotionsDataCallStatus, setFetchPromotionsDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [deletePromotionCallStatus, setDeletePromotionCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);
	const gotoCreatePage = () => navigate("/promotion/create");

	const handleDeleteClick = (item) => {
		setPromotionToDelete(item);
	};

	const handleConfirmDeleteClick = async() => {
		await deletePromotionAsync();

	};

	const fetchPromotionsDataAsync = async (pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchPromotionsData({
			auth0Token,
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			setPromotions,
			setPageNumber,
			setTotalResults,
			setStatusInProgress: () => setFetchPromotionsDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchPromotionsDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchPromotionsDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchPromotionsDataCallStatus(ApiCallStatus.Error)
		});
	};

	const deletePromotionAsync = async () => {
		if (!promotionToDelete?.promotionId) return;
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await deletePromotionData({
			auth0Token,
			promotionId: promotionToDelete.promotionId,
			setStatusInProgress: () => setDeletePromotionCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setDeletePromotionCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setDeletePromotionCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setDeletePromotionCallStatus(ApiCallStatus.Error)

		});
	};

	const authorizedPageLoad = async () => {
		const searchParams = new URLSearchParams(location.search);
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

		await Promise.all([
			fetchPromotionsDataAsync(pageNumberParam, pageSizeParam)
		]);

	};

	const onBackClick = () => {
		return navigate(-1);
	};

	const onPageNumberChange = async (_, {activePage}) => {
		await updateUrlQueryValue("pageNumber", activePage);
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const updateUrlQueryValue = async (key, value) => {
		const searchParams = new URLSearchParams(location.search);
		setFetchPromotionsDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/promotions',
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		scrollTop();

		const searchParams = new URLSearchParams(location.search);

		const pn = searchParams.get("pageNumber");

		if (!!pn) {
			fetchPromotionsDataAsync(pn);
		}
	}, [location]);

	useEffect(() => {
		if (deletePromotionCallStatus === ApiCallStatus.Succeeded) {
			setPromotionToDelete(null);
			setFetchPromotionsDataCallStatus(ApiCallStatus.NotStarted);
			authorizedPageLoad();
		}
	}, [deletePromotionCallStatus]);

	const allLoadingStates = [
		fetchPromotionsDataCallStatus,
		deletePromotionCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;

	return (
		<Fragment>
			<Promotions
				promotions={promotions}
				totalResults={totalResults}
				pageNumber={pageNumber}
				pageSize={pageSize}
				gotoCreatePage={gotoCreatePage}
				onPageNumberChange={onPageNumberChange}
				onBackClick={onBackClick}
				reloadPage={reloadPage}
				handleDeleteClick={handleDeleteClick}
				handleConfirmDeleteClick={handleConfirmDeleteClick}
				promotionToDelete={promotionToDelete}
				setPromotionToDelete={setPromotionToDelete}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PromotionsPage;
