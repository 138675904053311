import React from "react";
import AllRoute from "../router";
import "./App.css";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import PageLayout from "../PageLayout";
import { ToastContainer } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import 'react-toastify/dist/ReactToastify.css';
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithRedirectCallback = ({children, ...props}) => {
	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate((appState && appState.returnTo) || window.location.pathname);
	};
	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	);
};

const DesktopContent = () => (
	<Router>
		<Auth0ProviderWithRedirectCallback
			domain={process.env.REACT_APP_AUTHO_DOMAIN}
			clientId={process.env.REACT_APP_AUTHO_CLIENT_ID}
			authorizationParams={{
				redirect_uri: `${window.location.origin}`,
				audience: process.env.REACT_APP_AUTHO_AUDIENCE,
				scope: "openid profile email offline_access"
			}}
		>
			<PageLayout>
				<AllRoute/>
				<ToastContainer position="bottom-right" theme="light"/>
			</PageLayout>
		</Auth0ProviderWithRedirectCallback>
	</Router>
);

const NonDesktopMessage = () => (
	<div style={styles.messageBox}>
		<p>
			Our application is currently optimized for desktop use.
			We are working hard to bring you a mobile-friendly version very soon.
		</p>
	</div>
);

// Styles for the message box
const styles = {
	messageBox: {
		padding: '20px',
		margin: '20px',
		textAlign: 'center',
		backgroundColor: '#f7f7f7',
		borderRadius: '8px',
		boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
		border: '1px solid #eaeaea'
	}
};

const App = () => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1024px)'
	});

	return (
		<div>
			{isDesktopOrLaptop ? <DesktopContent/> : <NonDesktopMessage/>}
		</div>
	);
};


export default App;
