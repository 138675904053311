import React, {useState} from "react";
import moment from "moment";
import {
	Grid,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Pagination,
	Link,
	DialogTitle, DialogContent, DialogActions, Dialog
} from '@mui/material';
import { ArrowBack, Delete } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";


const PromotionProducts = ({
	onBackClick,
	onPageNumberChange,
	pageNumber,
	pageSize,
	totalResults,
	promotionProducts,
	reloadPage,
	handleDeleteClick,
	handleConfirmDeleteClick,
	promotionProductToDelete,
	setPromotionProductToDelete
}) => {

	const navigate = useNavigate();

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
				<div className="argus-title">
					Promotion Products
				</div>
			</div>
			{!!promotionProducts &&
				<Grid container spacing={2} sx={{ padding: '16px' }}>
					<Grid item xs={12}>
						<Button
							startIcon={<ArrowBack />}
							onClick={onBackClick}
							sx={{ mr: 2 }}
						>
							Back
						</Button>
					</Grid>

					{promotionProducts.length > 0 &&
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell><Typography variant="subtitle2">Sku</Typography></TableCell>
											<TableCell><Typography variant="subtitle2">Promotion</Typography></TableCell>
											<TableCell><Typography variant="subtitle2">Start Date</Typography></TableCell>
											<TableCell><Typography variant="subtitle2">End Date</Typography></TableCell>
											<TableCell><Typography variant="subtitle2" align="center">Actions</Typography></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{promotionProducts.map((product, index) => (
											<TableRow key={index} hover>
												<TableCell>{product.sku}</TableCell>
												<TableCell>
													<Link
														component="button"
														variant="body2"
														onClick={() => navigate(`/promotion/${product.promotionId}`)}
														underline="hover"
													>
														{product.promotionName}
													</Link>
												</TableCell>
												<TableCell>{moment(product.startDate).format("DD-MM-YYYY")}</TableCell>
												<TableCell>{moment(product.endDate).format("DD-MM-YYYY")}</TableCell>
												<TableCell align="center">
													<IconButton color="error" onClick={() => handleDeleteClick(product)}>
														<Delete size="small" />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					}

					{/* Pagination */}
					<Grid item xs={12} sx={{ textAlign: 'right', marginTop: '16px' }}>
						{totalResults > 0 && (
							<Pagination
								count={Math.ceil(totalResults / pageSize)}
								page={pageNumber}
								onChange={(e, page) => onPageNumberChange(page)}
								color="primary"
								variant="outlined"
								shape="rounded"
								size="small"
							/>
						)}
					</Grid>
				</Grid>
			}
			{!!promotionProductToDelete &&
				<Dialog open={!!promotionProductToDelete} onClose={() => setPromotionProductToDelete(null)}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						Are you sure you want to delete this promotion product: <b>{promotionProductToDelete?.sku}</b>?
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setPromotionProductToDelete(null)} color="primary">
							Cancel
						</Button>
						<Button onClick={handleConfirmDeleteClick} color="secondary">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			}

		</div>
	);
};

export default PromotionProducts;