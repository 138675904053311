import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
	fetchSalesOrderRequestData,
	retrySalesOrder
} from "../../main-component/PageFunctions/orderFunctions";
import {
	Box,
	Modal,
	Typography,
	CircularProgress,
	Button,
	Grid,
	IconButton,
	Alert,
	Divider, ListItemText, ListItem, List
} from "@mui/material";
import { CheckCircleOutline, ErrorOutline, Close, Refresh } from '@mui/icons-material';
import ArgusLogo from "../../img/logo.svg";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: "90%",
	maxWidth: 500,
	bgcolor: '#FFFFFF',
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
	textAlign: 'center',
};

const modalStates = {
	initialize: 1,
	fetchingData: 2,
	confirm: 3,
	dataNotFound: 4,
	retrying: 5,
	success: 6,
	error: 7
};

const RetrySalesOrderModal = ({
	open,
	setClose,
	salesOrderRequestId
}) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [modalState, setModalState] = useState(modalStates.initialize);
	const [modalError, setModalError] = useState("");
	const [salesOrderRequestData, setSalesOrderRequestData] = useState(null);

	const fetchSalesOrderRequestDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchSalesOrderRequestData({
			auth0Token,
			requestId: salesOrderRequestId,
			setSalesOrderRequestData,
			setModalError,
			setStatusInProgress: () => setModalState(modalStates.fetchingData),
			setStatusSuccess: () => setModalState(modalStates.confirm),
			setStatusFailed: () => setModalState(modalStates.dataNotFound),
			setStatusError: () => setModalState(modalStates.error)
		});
	};

	const retrySalesOrderAsync = async () => {
		let auth0Token;
		if (isAuthenticated) {
			auth0Token = await getAccessTokenSilently();
		}
		await retrySalesOrder({
			auth0Token,
			requestId: salesOrderRequestId,
			setStatusInProgress: () => setModalState(modalStates.retrying),
			setStatusSuccess: () => setModalState(modalStates.success),
			setStatusFailed: () => setModalState(modalStates.dataNotFound),
			setStatusError: () => setModalState(modalStates.error)
		});
	};

	const closeModal = () => {
		setModalState(modalStates.initialize);
		setModalError("");
		setClose();
	};

	useEffect(() => {
		if (open) fetchSalesOrderRequestDataAsync();
	}, [open]);

	return (
		<Modal open={open} onClose={closeModal}>
			<Box sx={style}>
				<IconButton onClick={closeModal} sx={{ position: 'absolute', top: 8, right: 8 }}>
					<Close />
				</IconButton>
				<img src={ArgusLogo} alt="Argus Logo" style={{ width: 80, marginBottom: 16 }} />
				<Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
					Retry Sales Order
				</Typography>
				<Divider sx={{ mb: 2 }} />

				{(modalState === modalStates.fetchingData || modalState === modalStates.retrying) && (
					<CircularProgress />
				)}

				{modalState === modalStates.confirm && salesOrderRequestData && (
					<>
						<Typography variant="body1" sx={{ mb: 2 }}>
							Are you sure you want to retry the sales order with the following information?
						</Typography>
						<List dense>
							<ListItem>
								<ListItemText primary="Order ID" secondary={salesOrderRequestData["order.id"]} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Customer Email" secondary={salesOrderRequestData["cust.email"]} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Customer Phone" secondary={salesOrderRequestData["cust.tell"]} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Delivery Address" secondary={`${salesOrderRequestData["delivery.address.line2"]}, ${salesOrderRequestData["delivery.city"]}, ${salesOrderRequestData["delivery.province"]}`} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Sales Representative" secondary={salesOrderRequestData["sales.rep"]} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Status" secondary={salesOrderRequestData["order.status"]} />
							</ListItem>
							<ListItem>
								<ListItemText primary="SKU" secondary={salesOrderRequestData["sku.no"]} />
							</ListItem>
						</List>

						<Button
							variant="contained"
							color="primary"
							onClick={retrySalesOrderAsync}
							sx={{ mt: 3, width: "100%" }}
						>
							Confirm
						</Button>
						<Button variant="outlined" color="error" onClick={closeModal} sx={{ mt: 1, width: "100%" }}>
							Cancel
						</Button>
					</>
				)}

				{modalState === modalStates.success && (
					<>
						<CheckCircleOutline color="success" sx={{ fontSize: 48, mb: 2 }} />
						<Typography variant="h6">Success</Typography>
						<Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
							The sales order has been successfully retried. Please refresh the page to see the updated status.
						</Typography>
						<Button variant="contained" color="primary" onClick={closeModal}>
							Close
						</Button>
					</>
				)}

				{modalState === modalStates.dataNotFound && (
					<>
						<ErrorOutline color="error" sx={{ fontSize: 48, mb: 2 }} />
						<Typography variant="h6">Data Not Found</Typography>
						<Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
							Sales order data could not be found. Please try again or contact support.
						</Typography>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Refresh />}
							onClick={fetchSalesOrderRequestDataAsync}
							sx={{ mr: 1 }}
						>
							Retry
						</Button>
						<Button variant="contained" color="error" onClick={closeModal}>
							Close
						</Button>
					</>
				)}

				{modalState === modalStates.error && modalError && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{modalError}
					</Alert>
				)}
			</Box>
		</Modal>
	);
};

export default RetrySalesOrderModal;
