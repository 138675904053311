import React from "react";
import DOMPurify from "dompurify";
import {
	Box,
	Button,
	Typography,
	IconButton,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ImageIcon from "@mui/icons-material/Image";
import CodeIcon from "@mui/icons-material/Code";
import { SouthAfricanRand } from "../../utils/helpers";
import moment from "moment";

const ProductCreateEditForm = ({ product, goBack }) => {
	const sanitizedData = () => ({
		__html: DOMPurify.sanitize(product.html),
	});

	return (
		<Box sx={{ padding: 4 }}>
			<Typography variant="h5" mb={2}>Product Details</Typography>
			<Divider sx={{ mb: 3 }} />

			<Grid container spacing={4}>
				{/* Product Information with QOH and Latest Cost */}
				<Grid item xs={12} md={6} lg={4}>
					<Card elevation={3}>
						<CardHeader
							title="Information"
							avatar={<InfoIcon color="primary" />}
							sx={{ bgcolor: "#f9f9f9" }}
						/>
						<CardContent>
							<Stack spacing={1}>
								<Typography variant="body2"><b>Name:</b> {product.name}</Typography>
								<Typography variant="body2"><b>SKU:</b> {product.sku}</Typography>
								<Typography variant="body2"><b>Category:</b> {product.productCategory?.replaceAll("_", " ")}</Typography>
								<Typography variant="body2"><b>Type:</b> {product.productType?.replaceAll("_", " ")}</Typography>
								<Typography variant="body2"><b>Description:</b> {product.description}</Typography>
								<Typography variant="body2"><b>Vendor:</b> {product.vendor}</Typography>
								<Typography variant="body2"><b>Quality:</b> {product.quality}</Typography>
								<Typography variant="body2"><b>Warranty:</b> {product.warranty}</Typography>
								<Typography variant="body2"><b>Availability:</b> {product.availability}</Typography>
								<Typography variant="body2"><b>Quantity On Hand:</b> {product.prices.quantityOnHand}</Typography>
								<Typography variant="body2"><b>Latest Cost:</b> {SouthAfricanRand.format(product.prices.latestCost)}</Typography>
							</Stack>
						</CardContent>
					</Card>
				</Grid>

				{/* Stock and Promotion Price Comparison */}
				<Grid item xs={12} md={6} lg={8}>
					<Card elevation={3}>
						<CardHeader
							title="Prices"
							avatar={<WarehouseIcon color="primary" />}
							sx={{ bgcolor: "#f9f9f9" }}
						/>
						<CardContent>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell><b>Level</b></TableCell>
										<TableCell><b>Standard Price</b></TableCell>
										{!!product?.promotionPrices && !!product.promotionPrices.length && <TableCell><b>{`Promotional Price (${product.promotionPrices.promotionName})`}</b></TableCell>}
									</TableRow>
								</TableHead>
								<TableBody>
									{[1, 2, 3, 4, 5, 6, 10].map((level) => (
										<TableRow key={level}>
											<TableCell><b>Retail {level}</b></TableCell>
											<TableCell>{SouthAfricanRand.format(product.prices[`retail${level}`])}</TableCell>
											{!!product?.promotionPrices && !!product.promotionPrices.length && (
												<TableCell>{SouthAfricanRand.format(product.promotionPrices[`retail${level}`])}</TableCell>
											)}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</CardContent>
					</Card>
				</Grid>

				{/* Product Image */}
				{product?.image && (
					<Grid item xs={12} md={6}>
						<Card elevation={3} sx={{ textAlign: "center" }}>
							<CardHeader
								title="Media"
								avatar={<ImageIcon color="primary" />}
								sx={{ bgcolor: "#f9f9f9" }}
							/>
							<CardContent>
								<Avatar
									src={product.image}
									alt={product.name}
									variant="square"
									sx={{ width: 200, height: 200, margin: "auto" }}
								/>
							</CardContent>
						</Card>
					</Grid>
				)}

				{/* HTML Content */}
				{product?.html && (
					<Grid item xs={12}>
						<Card elevation={3}>
							<CardHeader
								title="Body HTML"
								avatar={<CodeIcon color="primary" />}
								sx={{ bgcolor: "#f9f9f9" }}
							/>
							<CardContent>
								<Box dangerouslySetInnerHTML={sanitizedData()} sx={{ mt: 1 }} />
							</CardContent>
						</Card>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default ProductCreateEditForm;
