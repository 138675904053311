import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import {
	fetchCountryData,
	fetchProvinceData,
	fetchSalesPeopleData
} from "../PageFunctions/commonFunctions";
import OrderCreateEdit from "../../components/OrderCreateEdit";
import {
	fetchBillingAddressesForOrder,
	fetchDetailedOrderData,
	fetchShippingAddressesForOrder
} from "../PageFunctions/orderFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const ModalName = Object.freeze({
	RetrySalesOrder: Symbol(1),
	MarkAsPaid: Symbol(2),
});

const OrderCreateEditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {orderId} = useParams();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [orderData, setOrderData] = useState(null);
	const [countryData, setCountryData] = useState(null);
	const [provinceData, setProvinceData] = useState(null);
	const [salesPeopleData, setSalesPeopleData] = useState(null);
	const [billingAddressesData, setBillingAddressesData] = useState(null);
	const [shippingAddressesData, setShippingAddressesData] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [salesOrderRequestId, setSalesOrderRequestId] = useState(null);

	const [fetchOrderDataCallStatus, setFetchOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchBillingAddressesDataCallStatus, setFetchBillingAddressesDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchShippingAddressesDataCallStatus, setFetchShippingAddressesDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchProvinceDataCallStatus, setFetchProvinceDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchCountryDataCallStatus, setFetchCountryDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchSalesPeopleDataCallStatus, setFetchSalesPeopleDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoOrdersPage = () => navigate("/orders");
	const reloadPage = () => navigate(0);

	const fetchOrderDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchDetailedOrderData({
				auth0Token,
				orderId,
				setOrder: setOrderData,
				setStatusInProgress: () => setFetchOrderDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchOrderDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchOrderDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchOrderDataCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const fetchCountryDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchCountryData({
				auth0Token,
				setCountries: setCountryData,
				setStatusInProgress: () => setFetchCountryDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchCountryDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchCountryDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchCountryDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchProvinceDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchProvinceData({
				auth0Token,
				setProvinces: setProvinceData,
				setStatusInProgress: () => setFetchProvinceDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchProvinceDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchProvinceDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchProvinceDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchSalesPeopleDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchSalesPeopleData({
			auth0Token,
			setSalesPeople: setSalesPeopleData,
			setStatusInProgress: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Error)

		});
	};

	const fetchShippingAddressDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchShippingAddressesForOrder({
				auth0Token,
				orderId,
				setShippingAddressesData,
				setStatusInProgress: () => setFetchShippingAddressesDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchShippingAddressesDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchShippingAddressesDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchShippingAddressesDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchBillingAddressDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchBillingAddressesForOrder({
				auth0Token,
				orderId,
				setBillingAddressesData,
				setStatusInProgress: () => setFetchBillingAddressesDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchBillingAddressesDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchBillingAddressesDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchBillingAddressesDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const openRetrySaleOrderModal = () => {
		setOpenModal(ModalName.RetrySalesOrder);
	};

	const openMarkAsPaidModal = () => {
		setOpenModal(ModalName.MarkAsPaid);
	};

	const onRetrySaleOrderClick = async (requestId) => {
		if (!requestId) {
			//TODO: show error
		}
		setSalesOrderRequestId(requestId);
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchOrderDataAsync(),
			fetchProvinceDataAsync(),
			fetchCountryDataAsync(),
			fetchSalesPeopleDataAsync(),
			fetchShippingAddressDataAsync(),
			fetchBillingAddressDataAsync()
		]);
	};

	const closeModals = () => {
		setOpenModal(null);
		reloadPage();
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		if (!!salesOrderRequestId) {
			openRetrySaleOrderModal();
		}
	}, [salesOrderRequestId]);

	const allLoadingStates = [
		fetchOrderDataCallStatus,
		fetchProvinceDataCallStatus,
		fetchCountryDataCallStatus,
		fetchSalesPeopleDataCallStatus,
		fetchBillingAddressesDataCallStatus,
		fetchShippingAddressesDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<OrderCreateEdit
				onBackClick={gotoOrdersPage}
				order={orderData}
				provinces={provinceData}
				countries={countryData}
				salesPeople={salesPeopleData}
				billingAddresses={billingAddressesData}
				shippingAddresses={shippingAddressesData}
				openModal={openModal}
				onRetrySaleOrderClick={onRetrySaleOrderClick}
				onMarkAsPaidClick={openMarkAsPaidModal}
				salesOrderRequestId={salesOrderRequestId}
				showRetrySaleOrderModal={openModal === ModalName.RetrySalesOrder}
				showMarkAsPaidModal={openModal === ModalName.MarkAsPaid}
				closeModals={closeModals}
				reloadPage={reloadPage}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default OrderCreateEditPage;