import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, FormType } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import AnnouncementCreateEdit from "../../components/AnnouncementCreateEdit";
import { editAnnouncementData, fetchAnnouncementData } from "../PageFunctions/announcementFunctions";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";

const AnnouncementEditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {announcementId} = useParams();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [announcementData, setAnnouncementData] = useState(null);

	const [fetchAnnouncementDataCallStatus, setFetchAnnouncementDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editAnnouncementDataCallStatus, setEditAnnouncementDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoAnnouncementsPage = () => navigate("/announcements");
	const reloadPage = () => navigate(0);

	const fetchAnnouncementDataAsync = async () => {
		if (!!announcementId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchAnnouncementData({
				auth0Token,
				announcementId,
				setAnnouncementData,
				setStatusInProgress: () => setFetchAnnouncementDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchAnnouncementDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchAnnouncementDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchAnnouncementDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const editAnnouncementDataAsync = async ({
		announcementId,
		title,
		html,
		startDate,
		endDate,
		type,
		bannerImage
	}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await editAnnouncementData({
			auth0Token,
			announcementId,
			title,
			html,
			startDate,
			endDate,
			type,
			bannerImage,
			setStatusInProgress: () => setEditAnnouncementDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setEditAnnouncementDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setEditAnnouncementDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setEditAnnouncementDataCallStatus(ApiCallStatus.Error)

		});
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchAnnouncementDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	const onSubmit = async (values) => {
		const timezoneOffset = new Date().getTimezoneOffset();
		const offsetHours = parseInt(Math.abs(timezoneOffset / 60));
		const offsetMinutes = Math.abs(timezoneOffset % 60);
		const offsetSign = timezoneOffset > 0 ? "-" : "+"; // Note that the sign is reversed (JS is "+", ISO is "-")
		const formattedOffset = offsetSign + String(offsetHours).padStart(2, '0') + ":" + String(offsetMinutes).padStart(2, '0');

		const startDateInput = moment(`${values.startDate} ${values.startTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;
		const endDateInput = moment(`${values.endDate} ${values.endTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;

		let response = await editAnnouncementDataAsync({
			announcementId,
			html: values.html,
			bannerImage: values.bannerImage,
			startDate: startDateInput,
			endDate: endDateInput
		});

		if (!!response) {
			gotoAnnouncementsPage();
		}
	};

	const allLoadingStates = [
		fetchAnnouncementDataCallStatus,
		editAnnouncementDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<AnnouncementCreateEdit
				formType={FormType.Edit}
				onBackClick={gotoAnnouncementsPage}
				announcement={announcementData}
				submit={onSubmit}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default AnnouncementEditPage;