import React, { Fragment, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ApiCallStatus } from "../../utils/constants";
import { Backdrop, CircularProgress } from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import Users from "../../components/Users";
import { fetchUsersData } from "../PageFunctions/userFunctions";

import { useAuth0 } from "@auth0/auth0-react";
import { cloneDeep } from "lodash";

const ModalName = Object.freeze({
	TecfinityLink: Symbol(1),
	TecfinityDeLink: Symbol(2),
	AccountActivation: Symbol(3),
	AccountDeactivation: Symbol(4)
});

const UsersPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const location = useLocation();
	const [users, setUsers] = useState([]);
	const [tecfinityAccount, setTecfinityAccount] = useState(null);
	const [tradeAccount, setTradeAccount] = useState(null);
	const [userToEdit, setUserToEdit] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);
	const [filters, setFilters] = useState({
		name: null,
		email: null
	});
	const [sorting, setSorting] = useState({
		sortColumn: "DateCreated",
		sortDirection: "DESC"
	});

	const [openModal, setOpenModal] = useState(null);

	const [fetchUsersDataCallStatus, setFetchUsersDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);

	const fetchUsersDataAsync = async (pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchUsersData({
			auth0Token,
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			name: filters.name,
			email: filters.email,
			sortColumn: sorting.sortColumn,
			sortDirection: sorting.sortDirection,
			setUsers,
			setPageNumber,
			setTotalResults,
			setStatusInProgress: () => setFetchUsersDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchUsersDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchUsersDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchUsersDataCallStatus(ApiCallStatus.Error)
		});
	};

	const updateUrlQueryValue = async (key, value) => {
		const searchParams = new URLSearchParams(location.search);
		setFetchUsersDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/users',
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	const authorizedPageLoad = async () => {
		const searchParams = new URLSearchParams(location.search);
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

		await Promise.all([
			fetchUsersDataAsync(pageNumberParam, pageSizeParam)
		]);
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const onBackClick = () => {
		return navigate(-1);
	};

	const onPageNumberChange = async (e, value) => {
		await updateUrlQueryValue("pageNumber", value);
	};

	const onApplyFilters = ({
		name,
		email,
		sortColumn,
		sortDirection
	}) => {
		setFilters({
			name,
			email
		});
		setSorting({
			sortColumn,
			sortDirection
		});
		setFetchUsersDataCallStatus(ApiCallStatus.ReSend);
	};

	const openTecfinityAccountLinkModal = () => {
		setOpenModal(ModalName.TecfinityLink);
	};

	const openTecfinityAccountDeLinkModal = () => {
		setOpenModal(ModalName.TecfinityDeLink);
	};

	const openAccountActivationModal = () => {
		setOpenModal(ModalName.AccountActivation);
	};

	const openAccountDeactivationModal = () => {
		setOpenModal(ModalName.AccountDeactivation);
	};

	const closeModals = () => {
		setOpenModal(null);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		scrollTop();

		const searchParams = new URLSearchParams(location.search);

		const pn = searchParams.get("pageNumber");

		if (!!pn) {
			fetchUsersDataAsync(pn);
		}
	}, [location]);

	useEffect(() => {
		if (fetchUsersDataCallStatus === ApiCallStatus.ReSend) {
			const searchParams = new URLSearchParams(location.search);

			const pn = searchParams.get("pageNumber");

			fetchUsersDataAsync(pn ?? "1");
		}
	}, [fetchUsersDataCallStatus]);

	const allLoadingStates = [
		fetchUsersDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<Users
				users={users}
				tecfinityAccount={tecfinityAccount}
				tradeAccount={tradeAccount}
				userToEdit={userToEdit}
				showTecfinityAccountLinkModal={openModal === ModalName.TecfinityLink}
				showTecfinityAccountDeLinkModal={openModal === ModalName.TecfinityDeLink}
				showAccountActivationModal={openModal === ModalName.AccountActivation}
				showAccountDeactivationModal={openModal === ModalName.AccountDeactivation}
				openTecfinityAccountLinkModal={openTecfinityAccountLinkModal}
				openTecfinityAccountDeLinkModal={openTecfinityAccountDeLinkModal}
				openAccountActivationModal={openAccountActivationModal}
				openAccountDectivationModal={openAccountDeactivationModal}
				closeModals={closeModals}
				reloadPage={reloadPage}
				setUserToEdit={setUserToEdit}
				setTradeAccount={setTradeAccount}
				totalResults={totalResults}
				pageNumber={pageNumber}
				pageSize={pageSize}
				filters={filters}
				setFilters={setFilters}
				onPageNumberChange={onPageNumberChange}
				onApplyFilters={onApplyFilters}
				onBackClick={gotoHomePage}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default UsersPage;