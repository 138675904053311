import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import Home from "../../components/Home";
import { ApiCallStatus } from "../../utils/constants";

import { useAuth0 } from "@auth0/auth0-react";
import { fetchSalesOrderRequestData } from "../PageFunctions/orderFunctions";
import { fetchHomePageContentData } from "../PageFunctions/pageContentFunctions";

const HomePage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [pageContent, setPageContent] = useState(null);

	const [fetchHomePageContentDataCallStatus, setFetchHomePageContentDataCallStatus] = useState(ApiCallStatus.NotStarted);


	const reloadPage = () => navigate(0);

	const fetchHomePageContentDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchHomePageContentData({
			auth0Token,
			setPageContent,
			setStatusInProgress: () => setFetchHomePageContentDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchHomePageContentDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchHomePageContentDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchHomePageContentDataCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchHomePageContentDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === true) {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated]);

	const allLoadingStates = [
		fetchHomePageContentDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;

	return (
		<Fragment>
			<Home loading={pageIsLoading}
			      pageContent={pageContent}

			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default HomePage;
