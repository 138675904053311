import React from "react";

const PageLayout = (props) => {

	return (
		<main className="argus-main">
			{props.children}
		</main>
	);
};

export default PageLayout;