import React, { useState } from 'react';
import {
	Box,
	Grid,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Chip,
	Stack,
	IconButton,
	Tooltip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Divider,
	Pagination,
	Card,
	CardContent,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { green, red } from '@mui/material/colors';
import TecfinityAccountLinkModal from '../TecfinityAccountLinkModal';

const UsersPage = ({
	users,
	pageNumber,
	totalResults,
	pageSize,
	onPageNumberChange,
	showTecfinityAccountLinkModal,
	closeModals,
	userToEdit,
	tradeAccount,
	handleMenuOpen,
	onApplyFilters,
	reloadPage
}) => {
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);

	const applyFilters = () => {
		onApplyFilters({
			name,
			email,
			sortColumn,
			sortDirection
		});
	};

	return (
		<Box sx={{ padding: 4 }}>
			{/* Header */}
			<Typography variant="h5" mb={2}>User Management</Typography>
			<Divider sx={{ mb: 3 }} />

			{/* Filter and Sort Controls */}
			<Card sx={{ mb: 4 }}>
				<CardContent>
					<Typography variant="subtitle1" gutterBottom>
						Filter and Sort Users
					</Typography>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12} sm={4} md={3}>
							<TextField
								fullWidth
								label="Filter by Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="Enter name"
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={3}>
							<TextField
								fullWidth
								label="Filter by Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Enter email"
							/>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<FormControl fullWidth>
								<InputLabel>Sort By</InputLabel>
								<Select
									value={sortColumn}
									onChange={(e) => setSortColumn(e.target.value)}
								>
									<MenuItem value="firstname">First Name</MenuItem>
									<MenuItem value="lastname">Last Name</MenuItem>
									<MenuItem value="email">Email</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<FormControl fullWidth>
								<InputLabel>Sort Direction</InputLabel>
								<Select
									value={sortDirection}
									onChange={(e) => setSortDirection(e.target.value)}
								>
									<MenuItem value="ASC">Ascending</MenuItem>
									<MenuItem value="DESC">Descending</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={4} md={2} display="flex" justifyContent="flex-end">
							<Button variant="contained" color="primary" onClick={applyFilters}>
								Apply Filters
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{/* User Table */}
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Mobile</TableCell>
							<TableCell>Account Type</TableCell>
							<TableCell>Account Numbers</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user, index) => (
							<TableRow key={index}>
								<TableCell>
									<Link to={`/user/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
								</TableCell>
								<TableCell>{user.email}</TableCell>
								<TableCell>{user.phoneNumber}</TableCell>
								<TableCell>
									<Chip
										size="small"
										label={user.isTradeAccount ? 'Trade' : 'Retail'}
										color={user.isTradeAccount ? 'primary' : 'success'}
									/>
								</TableCell>
								<TableCell>
									<Stack direction="row" spacing={1}>
										{user.tradeAccounts.map((account, idx) => (
											<Chip
												key={idx}
												label={account.accountNumber}
												sx={{ marginRight: 0.5 }}
												color="primary"
												size="small"
											/>
										))}
										{!!user.tradeAccountsRequested.length && (
											<Chip
												label="Requested"
												sx={{ marginRight: 0.5 }}
												color="error"
												size="small"
											/>
										)}
									</Stack>
								</TableCell>
								<TableCell>
									{user.isActive ? (
										<CheckCircleIcon sx={{ color: green[500] }} />
									) : (
										<CancelIcon sx={{ color: red[500] }} />
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Pagination */}
			<Box display="flex" justifyContent="flex-end" mt={2}>
				{totalResults > 0 && (
					<Pagination
						count={Math.ceil(totalResults / pageSize)}
						page={pageNumber}
						onChange={onPageNumberChange}
						color="primary"
					/>
				)}
			</Box>

			{/* Tecfinity Account Link Modal */}
			{showTecfinityAccountLinkModal && (
				<TecfinityAccountLinkModal
					open={showTecfinityAccountLinkModal}
					setClose={closeModals}
					userToEdit={userToEdit}
					tradeAccount={tradeAccount}
					reloadPage={reloadPage}
					userId={userToEdit.id}
				/>
			)}
		</Box>
	);
};

export default UsersPage;
