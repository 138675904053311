import React, { Fragment } from "react";

import Error from "../../components/Error";

const ErrorPage = () => {

	return (
		<Fragment>
			<Error/>
		</Fragment>
	);
};
export default ErrorPage;
