import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import { fetchDetailedProductData } from "../PageFunctions/productFunctions";
import ProductCreateEdit from "../../components/ProductCreateEdit";
import { useAuth0 } from "@auth0/auth0-react";


const ProductCreateEditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const {sku} = useParams();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [productData, setProductData] = useState(null);

	const [fetchProductDataCallStatus, setFetchProductDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoProductsPage = () => navigate("/products");
	const reloadPage = () => navigate(0);

	const fetchProductDataAsync = async () => {
		if (!!sku) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchDetailedProductData({
				auth0Token,
				sku,
				setProductData,
				setStatusInProgress: () => setFetchProductDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchProductDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchProductDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchProductDataCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchProductDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	const allLoadingStates = [
		fetchProductDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<ProductCreateEdit
				onBackClick={gotoProductsPage}
				product={productData}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default ProductCreateEditPage;