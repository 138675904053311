import moment from 'moment';

export const SouthAfricanRand = new Intl.NumberFormat('en-ZA', {
	style: 'currency',
	currency: 'ZAR'
});

export const formatDateToDateTimeOffset = (date) => {
	const parsedDate = new Date(date); // Ensure it's a Date object
	const year = parsedDate.getFullYear();
	const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
	const day = String(parsedDate.getDate()).padStart(2, '0');
	return `${year}-${month}-${day} 00:00:00.0000000 +00:00`;
};