import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, FormType } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import {
	createPromotionProductData,
	deletePromotionProductData,
	editPromotionData, editPromotionProductData,
	fetchPromotionData,
	fetchPromotionProductsData
} from "../PageFunctions/promotionFunctions";
import PromotionEdit from "../../components/PromotionEdit";
import { fetchDetailedProductData, searchProductsBySku } from "../PageFunctions/productFunctions";

const PromotionEditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {promotionId} = useParams();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [promotionData, setPromotionData] = useState(null);
	const [promotionProductsData, setPromotionProductsData] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [detailedProductData, setDetailedProductData] = useState(null);

	const [fetchPromotionDataCallStatus, setFetchPromotionDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchPromotionProductsDataCallStatus, setFetchPromotionProductsDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editPromotionDataCallStatus, setEditPromotionDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editPromotionProductDataCallStatus, setEditPromotionProductDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [createPromotionProductDataCallStatus, setCreatePromotionProductDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [deletePromotionProductDataCallStatus, setDeletePromotionProductDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [searchProductsBySkuCallStatus, setSearchProductsBySkuCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchProductDataCallStatus, setFetchProductDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoPromotionsPage = () => navigate("/promotions");
	const reloadPage = () => navigate(0);

	const fetchPromotionDataAsync = async () => {
		if (!!promotionId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchPromotionData({
				auth0Token,
				promotionId,
				setPromotionData,
				setStatusInProgress: () => setFetchPromotionDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchPromotionDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchPromotionDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchPromotionDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchPromotionProductsDataAsync = async () => {
		if (!!promotionId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchPromotionProductsData({
				auth0Token,
				promotionId,
				setPromotionProductsData,
				setStatusInProgress: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const searchProductsBySkuAsync = async (queryString) => {
		if (!!queryString) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await searchProductsBySku({
				auth0Token,
				queryString,
				setSearchResults,
				setStatusInProgress: () => setSearchProductsBySkuCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setSearchProductsBySkuCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setSearchProductsBySkuCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setSearchProductsBySkuCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const editPromotionDataAsync = async ({
		name,
		startDate,
		endDate
	}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await editPromotionData({
			auth0Token,
			promotionId,
			name,
			startDate,
			endDate,
			setStatusInProgress: () => setEditPromotionDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setEditPromotionDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setEditPromotionDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setEditPromotionDataCallStatus(ApiCallStatus.Error)

		});
	};

	const editPromotionProductDataAsync = async (sku, prices) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await editPromotionProductData({
			auth0Token,
			promotionId,
			sku,
			retail1: prices.retail1,
			retail2: prices.retail2,
			retail3: prices.retail3,
			retail4: prices.retail4,
			retail5: prices.retail5,
			retail6: prices.retail6,
			retail10: prices.retail10,
			setStatusInProgress: () => setEditPromotionProductDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setEditPromotionProductDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setEditPromotionProductDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setEditPromotionProductDataCallStatus(ApiCallStatus.Error)

		});
	};

	const createPromotionProductDataAsync = async ({
		sku,
		retail1,
		retail2,
		retail3,
		retail4,
		retail5,
		retail6,
		retail10
	}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createPromotionProductData({
			auth0Token,
			promotionId,
			sku,
			retail1,
			retail2,
			retail3,
			retail4,
			retail5,
			retail6,
			retail10,
			setStatusInProgress: () => setCreatePromotionProductDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCreatePromotionProductDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCreatePromotionProductDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreatePromotionProductDataCallStatus(ApiCallStatus.Error)

		});
	};

	const fetchProductDataAsync = async (sku) => {
		if (!!sku) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchDetailedProductData({
				auth0Token,
				sku,
				setProductData: setDetailedProductData,
				setStatusInProgress: () => setFetchProductDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchProductDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchProductDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchProductDataCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const deletePromotionProductDataAsync = async (sku, promotionId) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await deletePromotionProductData({
			auth0Token,
			sku,
			promotionId,
			setStatusInProgress: () => setDeletePromotionProductDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setDeletePromotionProductDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setDeletePromotionProductDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setDeletePromotionProductDataCallStatus(ApiCallStatus.Error)

		});
	};

	const handleAddProduct = async (sku) => {
		await fetchProductDataAsync(sku);
	};

	const clearSearchResults = () => {
		setSearchResults([]);
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchPromotionDataAsync(),
			fetchPromotionProductsDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		if (createPromotionProductDataCallStatus === ApiCallStatus.Succeeded) {
			setFetchPromotionProductsDataCallStatus(ApiCallStatus.NotStarted);
			setFetchPromotionProductsDataCallStatus(ApiCallStatus.NotStarted);
			authorizedPageLoad();
		}
	}, [createPromotionProductDataCallStatus]);

	useEffect(() => {
		if (deletePromotionProductDataCallStatus === ApiCallStatus.Succeeded) {
			setFetchPromotionProductsDataCallStatus(ApiCallStatus.NotStarted);
			setFetchPromotionProductsDataCallStatus(ApiCallStatus.NotStarted);
			authorizedPageLoad();
		}
	}, [deletePromotionProductDataCallStatus]);

	useEffect(() => {
		if (editPromotionProductDataCallStatus === ApiCallStatus.Succeeded) {
			setFetchPromotionProductsDataCallStatus(ApiCallStatus.NotStarted);
			setFetchPromotionProductsDataCallStatus(ApiCallStatus.NotStarted);
			authorizedPageLoad();
		}
	}, [editPromotionProductDataCallStatus]);

	useEffect(() => {
		if (fetchProductDataCallStatus === ApiCallStatus.Succeeded && !!detailedProductData) {
			createPromotionProductDataAsync({
				sku: detailedProductData.sku,
				retail1: detailedProductData.prices?.retail1,
				retail2: detailedProductData.prices?.retail2,
				retail3: detailedProductData.prices?.retail3,
				retail4: detailedProductData.prices?.retail4,
				retail5: detailedProductData.prices?.retail5,
				retail6: detailedProductData.prices?.retail6,
				retail10: detailedProductData.prices?.retail10
			});
		}
	}, [fetchProductDataCallStatus, detailedProductData]);

	const allLoadingStates = [
		fetchPromotionDataCallStatus,
		editPromotionDataCallStatus,
		searchProductsBySkuCallStatus,
		fetchPromotionProductsDataCallStatus,
		editPromotionProductDataCallStatus,
		createPromotionProductDataCallStatus,
		fetchProductDataCallStatus,
		deletePromotionProductDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<PromotionEdit
				onBackClick={gotoPromotionsPage}
				promotion={promotionData}
				promotionProducts={promotionProductsData}
				searchResults={searchResults}
				search={searchProductsBySkuAsync}
				edit={editPromotionDataAsync}
				deleteProduct={deletePromotionProductDataAsync}
				editProduct={editPromotionProductDataAsync}
				addProduct={handleAddProduct}
				clearSearchResults={clearSearchResults}
				searchLoading={searchProductsBySkuCallStatus === ApiCallStatus.InProgress}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PromotionEditPage;