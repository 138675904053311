import React, { Fragment, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import Orders from "../../components/Orders";
import { cloneDeep } from "lodash";
import { fetchOrdersData } from "../PageFunctions/orderFunctions";

import { useAuth0 } from "@auth0/auth0-react";
import { fetchSalesPeopleData } from "../PageFunctions/commonFunctions";

const ModalName = Object.freeze({
	MarkAsPaid: Symbol(1),
});

const OrdersPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const [orders, setOrders] = useState([]);
	const [salesPeopleData, setSalesPeopleData] = useState(null);

	const [orderToEdit, setOrderToEdit] = useState(null);

	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);
	const [filters, setFilters] = useState({
		salesPersonCode: null,
		orderStatusId: null,
		orderPaymentStatusId: null,
	});
	const [sorting, setSorting] = useState({
		sortColumn: "DateCreated",
		sortDirection: "DESC"
	});


	const [openModal, setOpenModal] = useState(null);

	const [fetchOrdersDataCallStatus, setFetchOrdersDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchSalesPeopleDataCallStatus, setFetchSalesPeopleDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);

	const fetchSalesPeopleDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchSalesPeopleData({
			auth0Token,
			setSalesPeople: setSalesPeopleData,
			setStatusInProgress: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Error)

		});
	};

	const fetchOrdersDataAsync = async (pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrdersData({
			auth0Token,
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			salesPersonCode: filters.salesPersonCode,
			orderStatusId: filters.orderStatusId,
			orderPaymentStatusId: filters.orderPaymentStatusId,
			sortColumn: sorting.sortColumn,
			sortDirection: sorting.sortDirection,
			setOrders,
			setPageNumber,
			setTotalResults,
			setStatusInProgress: () => setFetchOrdersDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchOrdersDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchOrdersDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchOrdersDataCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {
		const searchParams = new URLSearchParams(location.search);
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

		await Promise.all([
			fetchOrdersDataAsync(pageNumberParam, pageSizeParam),
			fetchSalesPeopleDataAsync()
		]);
	};

	const onBackClick = () => {
		return navigate(-1);
	};

	const onPageNumberChange = async (e, value) => {
		await updateUrlQueryValue("pageNumber", value);
	};

	const onApplyFilters = ({
		salesPersonCode,
		orderStatusId,
		orderPaymentStatusId,
		sortColumn,
		sortDirection
	}) => {
		setFilters({
			salesPersonCode,
			orderStatusId,
			orderPaymentStatusId,
		});
		setSorting({
			sortColumn,
			sortDirection
		});
		setFetchOrdersDataCallStatus(ApiCallStatus.ReSend);
	};

	const openMarkAsPaidModal = () => {
		setOpenModal(ModalName.MarkAsPaid);
	};

	const closeModals = () => {
		setOpenModal(null);
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const updateUrlQueryValue = async (key, value) => {
		const searchParams = new URLSearchParams(location.search);
		setFetchOrdersDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/orders',
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		scrollTop();

		const searchParams = new URLSearchParams(location.search);

		const pn = searchParams.get("pageNumber");

		if (!!pn) {
			fetchOrdersDataAsync(pn);
		}
	}, [location]);

	useEffect(() => {
		if (fetchOrdersDataCallStatus === ApiCallStatus.ReSend) {
			const searchParams = new URLSearchParams(location.search);

			const pn = searchParams.get("pageNumber");

			fetchOrdersDataAsync(pn ?? "1");
		}
	}, [fetchOrdersDataCallStatus]);

	const allLoadingStates = [
		fetchOrdersDataCallStatus,
		fetchSalesPeopleDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;

	return (
		<Fragment>
			<Orders
				orders={orders}
				totalResults={totalResults}
		        pageNumber={pageNumber}
		        pageSize={pageSize}
		        onPageNumberChange={onPageNumberChange}
				salesPeople={salesPeopleData}
				orderToEdit={orderToEdit}
				setOrderToEdit={setOrderToEdit}
				showMarkAsPaidModal={openModal === ModalName.MarkAsPaid}
				openMarkAsPaidModal={openMarkAsPaidModal}
				closeModals={closeModals}
		        onBackClick={onBackClick}
				reloadPage={reloadPage}
				onApplyFilters={onApplyFilters}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default OrdersPage;
