import { toast } from "react-toastify";
import {
	getSmcTecfinitySalesOrderDetail,
	getSmcTecfinitySalesOrderResponses,
	getTecfinityAccount, postUpdateTecfinityAccount
} from "../../api/tecfinityController";

export const fetchSmcTecfinitySalesOrderResponseData = async ({
	auth0Token,
	startDate,
	endDate,
	pageSize,
	pageNumber,
	setResponses,
	setPageNumber,
	setTotalResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getSmcTecfinitySalesOrderResponses({
			auth0Token,
			startDate,
			endDate,
			pageSize,
			pageNumber
		});

		if (!!response?.success) {
			setResponses(response.data);
			setPageNumber(response.skip);
			setTotalResults(response.total);
			setStatusSuccess();
		}
		else {
			setResponses([]);
			setTotalResults(0);
			toast("Could not get SMC Tecfinity Sales Order data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			setStatusFailed();
		}

	}
	catch (error) {
		toast("Could not get SMC Tecfinity Sales Order data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
		setStatusError();
	}
};

export const fetchDetailedSalesOrderData = async ({
	auth0Token,
	orderId,
	setSalesOrderDetail,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getSmcTecfinitySalesOrderDetail({orderId, auth0Token});
		if (!!response?.success) {
			setSalesOrderDetail(response.data);
			setStatusSuccess();
		}
		else {
			toast("Could not get SMC Tecfinity Sales Order details.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			setStatusFailed();
		}

	}
	catch (error) {
		toast("Could not get SMC Tecfinity Sales Order details.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
		setStatusError();
	}
};

export const getTecfinityAccountData = async ({
	auth0Token,
	setTecfinityAccountFound,
	setModalError,
	accountNumber,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getTecfinityAccount({
			accountNumber,
			auth0Token
		});

		if (response.success) {
			setTecfinityAccountFound(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		setStatusError();
		setModalError("Could not load Tecfinity information. Please try again later or contact support.");
		console.error(error);
	}
};

export const updateTecfinityAccountData = async ({
	auth0Token,
	accountNumber,
	setModalError,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postUpdateTecfinityAccount({
			accountNumber,
			auth0Token
		});

		if (response.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		setStatusError();
		setModalError("Could not update Tecfinity information. Please try again later or contact support.");
		console.error(error);
	}
};