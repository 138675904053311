import {
	activateAnnouncement,
	createAnnouncement,
	deactivateAnnouncement,
	editAnnouncement,
	getAnnouncement,
	getAnnouncementsOverview
} from "../../api/announcementController";
import { toast } from "react-toastify";
import { sortBy } from "lodash";
import moment from "moment";

export const createAnnouncementData = async ({
	auth0Token,
	title,
	html,
	bannerImage,
	startDate,
	endDate,
	type,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await createAnnouncement({
			websiteId: process.env.REACT_APP_PUBLIC_WEBSITE_ID,
			auth0Token,
			title,
			html,
			bannerImage,
			startDate,
			endDate,
			type
		});

		if (response.success) {
			setStatusSuccess();
			toast("Announcement created.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not create announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const editAnnouncementData = async ({
	auth0Token,
	announcementId,
	title,
	html,
	startDate,
	endDate,
	type,
	bannerImage,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await editAnnouncement({
			websiteId: process.env.REACT_APP_PUBLIC_WEBSITE_ID,
			announcementId,
			auth0Token,
			title,
			html,
			startDate,
			endDate,
			bannerImage,
			type
		});

		if (response.success) {
			setStatusSuccess();
			toast("Announcement edited.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not create announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const activateAnnouncementData = async ({
	auth0Token,
	announcementId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError,
	setErrorMessage
}) => {
	setStatusInProgress();
	try {
		let response = await activateAnnouncement({
			websiteId: process.env.REACT_APP_PUBLIC_WEBSITE_ID,
			announcementId,
			auth0Token
		});

		if (response.success) {
			setStatusSuccess();
			toast("Announcement activated.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			setErrorMessage(response.message);
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not activate announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const deactivateAnnouncementData = async ({
	auth0Token,
	announcementId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError,
	setErrorMessage
}) => {
	setStatusInProgress();
	try {
		let response = await deactivateAnnouncement({
			websiteId: process.env.REACT_APP_PUBLIC_WEBSITE_ID,
			announcementId,
			auth0Token
		});

		if (response.success) {
			setStatusSuccess();
			toast("Announcement deactivated.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		}
		else {
			setStatusFailed();
			setErrorMessage(response.message);
		}
	}
	catch (error) {
		setStatusError();
		console.error(error);
		toast("Could not deactivate announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchAnnouncementsData = async ({
	auth0Token,
	pageSize,
	pageNumber,
	setAnnouncements,
	setTotalResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getAnnouncementsOverview({
			auth0Token,
			pageSize,
			pageNumber
		});

		if (response?.success) {
			const announcements = response.data;
			const total = response.data.total;
			const skip = response.data.skip;
			const limit = response.data.limit;
			const announcementsSorted = sortBy(announcements, x => moment(x.dateCreated).format("X"));

			setAnnouncements(announcementsSorted);
			setStatusSuccess();
			setTotalResults(response.total);
		}
		else {
			setAnnouncements([]);
			setStatusFailed();
			setTotalResults(0);
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get announcements. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchAnnouncementData = async ({
	auth0Token,
	announcementId,
	setAnnouncementData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getAnnouncement({
			announcementId,
			auth0Token
		});

		if (response?.success) {
			const announcement = response.data;
			setAnnouncementData(announcement);
			setStatusSuccess();
		}
		else {
			console.error(response.message);
			setStatusFailed();
			toast("Could not get announcement. Please try again later.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		setStatusError();
	}
};