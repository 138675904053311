import React, { Fragment, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import { fetchGenericPageContentData } from "../PageFunctions/pageContentFunctions";


const PageWrapper = (props) => {
	const {getAccessTokenSilently, isLoading, isAuthenticated} = useAuth0();
	const [auth0Token, setAuth0Token] = useState(null);
	const [tokenFetched, setTokenFetched] = useState(false);
	const [genericPageContent, setGenericPageContent] = useState(null);
	const [genericDataLoaded, setGenericDataLoaded] = useState(false);

	const [fetchGenericPageContentCallStatus, setFetchGenericPageContentCallStatus] = useState(ApiCallStatus.NotStarted);

	const scrollTop = () => {
	};

	useEffect(() => {
		document.querySelector("body").scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, []);

	useEffect(() => {
		const fetchToken = async () => {
			const token = await getAccessTokenSilently();
			setTokenFetched(true);
			setAuth0Token(token);
		};

		if (isLoading === false) {
			if (isAuthenticated === true) {
				fetchToken();
			} else {
				setTokenFetched(true);
			}

		}
	}, [getAccessTokenSilently, isLoading, isAuthenticated]);

	useEffect(() => {
		if (tokenFetched === true && isLoading === false) {
			if (isAuthenticated === true && !!auth0Token) {
				setFetchGenericPageContentCallStatus(ApiCallStatus.InProgress);
				fetchGenericPageContentData({
					auth0Token,
					setPageContent: setGenericPageContent,
					setStatusInProgress: () => setFetchGenericPageContentCallStatus(ApiCallStatus.InProgress),
					setStatusSuccess: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Succeeded),
					setStatusFailed: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Failed),
					setStatusError: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Error)
				});
			} else if (isAuthenticated === false) {
				setFetchGenericPageContentCallStatus(ApiCallStatus.Succeeded);
			}
		}
	}, [auth0Token, tokenFetched, isLoading, isAuthenticated]);

	useEffect(() => {
		if (fetchGenericPageContentCallStatus === ApiCallStatus.Succeeded) {
			setGenericDataLoaded(true);
		}
	}, [fetchGenericPageContentCallStatus]);


	const allLoadingStates = [
		fetchGenericPageContentCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	let additionalProps;
	let childrenWithProps;

	if (genericDataLoaded === true) {
		additionalProps = {
			genericDataLoaded,
			genericPageContent
		};

		childrenWithProps = React.Children.map(props.children, (child) => {
			return React.cloneElement(child, { ...props, ...additionalProps });
		});
	}

	return (
		<Fragment>
			{!!pageIsLoading &&
				<div>Loading...</div>
			}
			{!pageIsLoading && !!additionalProps && childrenWithProps &&
				<>
					<SiteHeader genericPageContent={genericPageContent}
					            genericDataLoaded={genericDataLoaded}
					/>
					{childrenWithProps}
				</>
			}
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PageWrapper;