import axios from "axios";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_ORDER_API_URL;

export const getUserAddresses = async ({userId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/address/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `user/${userId}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getBillingAddressesForOrder = async ({orderId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/address/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `order/billing/${orderId}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getShippingAddressesForOrder = async ({orderId, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/address/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `order/shipping/${orderId}`;

		return await instance.get(requestUrl)
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};