import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { ApiCallStatus } from "../../utils/constants";
import { cloneDeep } from "lodash";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import { fetchProductsData, updateStockInfo } from "../PageFunctions/productFunctions";
import Products from "../../components/Products";

import { useAuth0 } from "@auth0/auth0-react";

const ModalName = Object.freeze({
	AnnouncementActivation: Symbol(1),
	AnnouncementDeactivation: Symbol(2),
	UpdateStockInfo: Symbol(3),
});

const ProductsPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const [searchParams, setSearchParams] = useSearchParams();
	const [products, setProducts] = useState([]);

	const [openModal, setOpenModal] = useState(null);

	const [filterInput, setFilterInput] = useState({
		sku: "",
		name: ""
	});

	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(50);
	const [sortColumn, setSortColumn] = useState('');
	const [sortDirection, setSortDirection] = useState('ASC');

	const [fetchProductsDataCallStatus, setFetchProductsDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);

	const fetchProductsDataAsync = async (pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsData({
			auth0Token,
			pageNumber: pageNumberParam,
			pageSize: pageSizeParam,
			sku: filterInput.sku,
			name: filterInput.name,
			setProducts,
			setTotalResults,
			setPageNumber,
			setStatusInProgress: () => setFetchProductsDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchProductsDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchProductsDataCallStatus(ApiCallStatus.Failed),
			setErrorMessage: () => setFetchProductsDataCallStatus(ApiCallStatus.Error)
		});
	};

	const onPageNumberChange = async (e, value) => {
		let newPageNumber = Number(value);
		if (!isNaN(newPageNumber)) {
			await updateUrlQueryValue("pageNumber", Number(value));
		}
	};

	const updateUrlQueryValue = async (key, value) => {
		setFetchProductsDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/products',
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	const authorizedPageLoad = async () => {
		let pageNumberParam = Number(searchParams.get('pageNumber'));
		if (!pageNumberParam || isNaN(pageNumberParam)) {
			pageNumberParam = 1;
		}
		const pageSizeParam = 50;

		await Promise.all([
			fetchProductsDataAsync(pageNumberParam, pageSizeParam)
		]);
	};

	const closeModals = () => {
		setOpenModal(null);
		navigate(0);
	};

	const onFilterInputChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		const filterInputClone = cloneDeep(filterInput);
		filterInputClone[name] = value;
		setFilterInput(filterInputClone);
	};

	const onApplyFiltersClick = async () => {
		let pageNumberParam = Number(searchParams.get('pageNumber'));
		const pageSizeParam = 50;

		if (!pageNumberParam || isNaN(pageNumberParam)) {
			pageNumberParam = 1;
		}

		await fetchProductsDataAsync(pageNumberParam, pageSizeParam);
	};

	const onClearFiltersClick = () => {
		const newFilters = {
			sku: "",
			name: ""
		};

		setFilterInput(newFilters);
	};

	const openUpdateStockInfoModal = () => {
		setOpenModal(ModalName.UpdateStockInfo);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		scrollTop();
		let pageNumberParam = Number(searchParams.get('pageNumber'));
		const pageSizeParam = 50;

		if (!pageNumberParam || isNaN(pageNumberParam)) {
			return;
		}

		fetchProductsDataAsync(pageNumberParam, pageSizeParam);
	}, [searchParams]);

	const allLoadingStates = [
		fetchProductsDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<Products
				products={products}
				filters={filterInput}
				totalResults={totalResults}
				pageNumber={pageNumber}
				pageSize={pageSize}
				onPageNumberChange={onPageNumberChange}
				onFilterChange={onFilterInputChange}
				onApplyFiltersClick={onApplyFiltersClick}
				onClearFiltersClick={onClearFiltersClick}
				openUpdateStockInfoModal={openUpdateStockInfoModal}
				showUpdateStockInfoModal={openModal === ModalName.UpdateStockInfo}
				onBackClick={gotoHomePage}
				closeModals={closeModals}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default ProductsPage;