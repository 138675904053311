import React, { useState } from 'react';
import {
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	IconButton,
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Save, ArrowBack, UploadFile, Replay } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { formatDateToDateTimeOffset } from "../../utils/helpers";
import moment from "moment";

const requiredColumns = ['sku', 'retail1', 'retail2', 'retail3', 'retail4', 'retail5', 'retail6', 'retail10'];

const PromotionCreate = ({
	onSubmit
}) => {
	const [promotionName, setPromotionName] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [file, setFile] = useState(null);
	const [fileData, setFileData] = useState([]);
	const [validationError, setValidationError] = useState(null);
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const navigate = useNavigate();

	const handleBackClick = () => {
		navigate('/promotions');
	};

	const handleFileUpload = (event) => {
		const uploadedFile = event.target.files[0];

		// Reset state on new file upload
		setFile(uploadedFile);
		setFileData([]);
		setValidationError(null);
		setIsFileUploaded(false);

		const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();
		if (fileExtension === 'csv') {
			parseCSVFile(uploadedFile);
		} else {
			setValidationError('Unsupported file format. Please upload a CSV file.');
			setFileData([]);
		}
	};

	const parseCSVFile = (file) => {
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: (result) => {
				validateColumns(result.data);
			},
			error: () => {
				setValidationError('Error reading the CSV file. Please try again.');
				setFileData([]);
			},
		});
	};

	const validateColumns = (data) => {
		const missingRows = [];
		data.forEach((row, index) => {
			const missingColumns = requiredColumns.filter((col) => !(col in row));
			if (missingColumns.length > 0) {
				missingRows.push(`Line ${index + 2}: missing ${missingColumns.join(', ')}`);
			}
		});

		if (missingRows.length > 0) {
			setValidationError(`Validation errors found:\n${missingRows.join('\n')}`);
			setFileData([]);
			setIsFileUploaded(false);
		} else {
			setValidationError(null);
			setFileData(data);
			setIsFileUploaded(true);
		}
	};

	const handleReset = () => {
		// Reset all states to start fresh
		setFile(null);
		setFileData([]);
		setValidationError(null);
		setIsFileUploaded(false);
	};

	const handleStartDateChange = (newDate) => {
		setStartDate(moment(newDate).format("DD/MM/YYYY"));
	};

	const handleEndDateChange = (newDate) => {
		setEndDate(moment(newDate).format("DD/MM/YYYY"));
	};

	const handleSave = async () => {
		if (promotionName && startDate && endDate && file && !validationError) {
			const formattedStartDate = formatDateToDateTimeOffset(moment(startDate, "DD/MM/YYYY"));
			const formattedEndDate = formatDateToDateTimeOffset(moment(endDate, "DD/MM/YYYY"));


			const processedFileData = fileData.map((row) => {
				const processedRow = { ...row };
				requiredColumns.forEach((col) => {
					if (col !== 'sku' && row[col] != null) {
						processedRow[col] = parseFloat(row[col]);
					}
				});
				return processedRow;
			});

			const promotionData = {
				name: promotionName,
				startDate: formattedStartDate,
				endDate: formattedEndDate,
				products: processedFileData,
			};

			await onSubmit(promotionData);

			// Send promotionData to the API (replace with actual API call)
		} else {
			alert('Please complete all fields and ensure the file is valid');
		}
	};

	return (
		<Paper elevation={3} sx={{ padding: '24px', maxWidth: 600, margin: 'auto', mt: 4 }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
				<Button
					startIcon={<ArrowBack />}
					onClick={handleBackClick}
					sx={{ mr: 2 }}
				>
					Back
				</Button>
				<Typography variant="h6" fontWeight="bolder">
					Create New Promotion
				</Typography>

			</Stack>

			<Grid container spacing={2}>
				{/* Promotion Name */}
				<Grid item xs={12}>
					<TextField
						label="Promotion Name"
						variant="outlined"
						fullWidth
						value={promotionName}
						onChange={(e) => setPromotionName(e.target.value)}
						required
					/>
				</Grid>

				{/* Start Date */}
				<Grid item xs={6}>
					<DatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						dateFormat="dd/MM/yyyy"
						customInput={
							<TextField
								label="Start Date"
								variant="outlined"
								fullWidth
							/>
						}
					/>
				</Grid>

				{/* End Date */}
				<Grid item xs={6}>
					<DatePicker
						selected={endDate}
						onChange={(date) => setEndDate(date)}
						dateFormat="dd/MM/yyyy"
						customInput={
							<TextField
								label="End Date"
								variant="outlined"
								fullWidth
							/>
						}
					/>
				</Grid>

				{/* File Upload */}
				<Grid item xs={12}>
					{isFileUploaded ? (
						<Button
							variant="outlined"
							startIcon={<Replay />}
							fullWidth
							onClick={handleReset}
							sx={{ justifyContent: 'flex-start' }}
						>
							Upload Different File
						</Button>
					) : (
						<Button
							variant="outlined"
							component="label"
							startIcon={<UploadFile />}
							fullWidth
							sx={{ justifyContent: 'flex-start' }}
						>
							{file ? file.name : 'Upload CSV File'}
							<input
								type="file"
								hidden
								onChange={handleFileUpload}
								accept=".csv"
							/>
						</Button>
					)}
					{validationError && (
						<Typography variant="body2" color="error" mt={1} style={{ whiteSpace: 'pre-line' }}>
							{validationError}
						</Typography>
					)}
					{file && !validationError && (
						<Typography variant="body2" color="textSecondary" mt={1}>
							File validated successfully: {file.name}
						</Typography>
					)}
				</Grid>

				{fileData.length > 0 && !validationError && (
					<Grid item xs={12}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										{requiredColumns.map((col) => (
											<TableCell key={col}>{col.toUpperCase()}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{fileData.map((row, index) => (
										<TableRow key={index}>
											{requiredColumns.map((col) => (
												<TableCell key={`${index}-${col}`}>{row[col] || 'N/A'}</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				)}

				{/* Save Button */}
				<Grid item xs={12}>
					<Box textAlign="center" mt={2}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Save />}
							onClick={handleSave}
							disabled={!promotionName || !startDate || !endDate || !file || validationError}
						>
							Save Promotion
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default PromotionCreate;
