import React from 'react';
import moment from "moment";
import {
	Box,
	Button,
	Stack,
	Card,
	CardContent,
	CardHeader,
	Typography,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TableHead,
	Grid,
	IconButton
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PaymentIcon from '@mui/icons-material/Payment';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SouthAfricanRand } from "../../utils/helpers";
import { find } from "lodash";

const OrderCreateEditForm = ({
	order,
	goBack,
	salesPeople,
	billingAddresses,
	shippingAddresses,
	provinces,
	countries,
	onRetrySaleOrderClick,
	onMarkAsPaidClick
}) => {
	const salesPerson = find(salesPeople, (x) => x.code === order.salesPersonCode);

	// Section Component with Title, Icon, and Content
	const InfoSection = ({ title, icon, children }) => (
		<Card elevation={2} sx={{ mb: 3 }}>
			<CardHeader avatar={icon} title={title} sx={{ backgroundColor: '#f9f9f9', color: 'primary.main' }} />
			<Divider />
			<CardContent>{children}</CardContent>
		</Card>
	);

	// Render a Row for Information Display
	const renderInfoRow = (label, value) => (
		<TableRow>
			<TableCell sx={{ fontWeight: 600, color: 'text.secondary' }}>{label}</TableCell>
			<TableCell>{value}</TableCell>
		</TableRow>
	);

	return (
		<Box sx={{ p: 4 }}>
			<Typography variant="h5" mb={2}>Order Details</Typography>
			<Divider sx={{ mb: 3 }} />

			<Grid container spacing={3} sx={{ mb: 3 }}>
				<Grid item xs={12} sx={{ textAlign: 'right' }}>
					{order.paymentStatus !== "Paid" && (
						<Button onClick={onMarkAsPaidClick} startIcon={<CheckIcon />} variant="contained"  color="success">
							Mark as Paid
						</Button>
					)}
				</Grid>
			</Grid>

			<Divider sx={{ mb: 3 }} />

			<Grid container spacing={3}>
				{/* Order Information */}
				<Grid item xs={12} md={6}>
					<InfoSection title="Order Information" icon={<InfoIcon color="primary" />}>
						<Table size="small">
							<TableBody>
								{renderInfoRow("Order Number", order.orderNumber)}
								{renderInfoRow("Created On", moment(order.dateCreated).format("YYYY-MM-DD | HH:mm:ss"))}
								{renderInfoRow("Customer Name", order.customerName)}
								{renderInfoRow("Customer Email", order.customerEmail)}
								{renderInfoRow("Order Status", order.orderStatus)}
								{renderInfoRow("Account Number", order.tecfinityAccountNumber)}
								{renderInfoRow("Sales Person", salesPerson?.name ?? order.salesPersonCode)}
								{renderInfoRow("Ship Via", order.shipVia)}
								{renderInfoRow("Payment Status", order.paymentStatus)}
								{renderInfoRow("Fulfillment Status", order.fulfillmentStatus)}
								{renderInfoRow("Order Total", SouthAfricanRand.format(order.total))}
							</TableBody>
						</Table>
					</InfoSection>
				</Grid>

				{/* Logistics Information */}
				{order?.logistics && (
					<Grid item xs={12} md={6}>
						<InfoSection title="Logistics" icon={<LocalShippingIcon color="primary" />}>
							<Table size="small">
								<TableBody>
									{renderInfoRow("Method", order.logistics.logisticsMethod)}
									{renderInfoRow("Fee", SouthAfricanRand.format(order.logistics.fee))}
								</TableBody>
							</Table>
						</InfoSection>
					</Grid>
				)}

				{/* Billing and Shipping Addresses */}
				{order?.logistics && (
					<>
						<Grid item xs={12} md={6}>
							<InfoSection title="Billing Address">
								<Table size="small">
									<TableBody>
										{renderInfoRow("First Name", order.logistics.billingAddress?.firstName)}
										{renderInfoRow("Last Name", order.logistics.billingAddress?.lastName)}
										{renderInfoRow("Street Address", order.logistics.billingAddress?.streetAddress)}
										{renderInfoRow("City", order.logistics.billingAddress?.city)}
										{renderInfoRow("Suburb", order.logistics.billingAddress?.suburb)}
										{renderInfoRow("Postal Code", order.logistics.billingAddress?.postalCode)}
										{renderInfoRow("Province", find(provinces, x => x.provinceId === order.logistics.billingAddress?.provinceId)?.name)}
										{renderInfoRow("Country", find(countries, x => x.countryId === order.logistics.billingAddress?.countryId)?.name)}
									</TableBody>
								</Table>
							</InfoSection>
						</Grid>
						{!!order.logistics.shippingAddress &&
							<Grid item xs={12} md={6}>
								<InfoSection title="Shipping Address">
									<Table size="small">
										<TableBody>
											{renderInfoRow("First Name", order.logistics.shippingAddress?.firstName)}
											{renderInfoRow("Last Name", order.logistics.shippingAddress?.lastName)}
											{renderInfoRow("Street Address", order.logistics.shippingAddress?.streetAddress)}
											{renderInfoRow("City", order.logistics.shippingAddress?.city)}
											{renderInfoRow("Suburb", order.logistics.shippingAddress?.suburb)}
											{renderInfoRow("Postal Code", order.logistics.shippingAddress?.postalCode)}
											{renderInfoRow("Province", find(provinces, x => x.provinceId === order.logistics.shippingAddress?.provinceId)?.name)}
											{renderInfoRow("Country", find(countries, x => x.countryId === order.logistics.shippingAddress?.countryId)?.name)}
										</TableBody>
									</Table>
								</InfoSection>
							</Grid>
						}
					</>
				)}

				{/* Order Items */}
				{order?.orderItems?.length > 0 && (
					<Grid item xs={12}>
						<InfoSection title="Products" icon={<ShoppingBagIcon color="primary" />}>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Sku</TableCell>
											<TableCell align="right">Quantity</TableCell>
											<TableCell align="right">Price</TableCell>
											<TableCell align="right">Amount</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{order.orderItems.map((item, index) => (
											<TableRow key={index}>
												<TableCell>{item.sku}</TableCell>
												<TableCell align="right">{item.quantity}</TableCell>
												<TableCell align="right">{SouthAfricanRand.format(item.priceSnapshot)}</TableCell>
												<TableCell align="right">{SouthAfricanRand.format(item.priceSnapshot * item.quantity)}</TableCell>
											</TableRow>
										))}
										<TableRow>
											<TableCell />
											<TableCell />
											<TableCell />
											<TableCell />
										</TableRow>
										<TableRow>
											<TableCell />
											<TableCell />
											<TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
											<TableCell sx={{ fontWeight: "bold" }} align="right">
												{SouthAfricanRand.format(order.subtotal)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</InfoSection>
					</Grid>
				)}

				<Grid item xs={6}>
					<InfoSection title="Invoice">
						<TableContainer>
							<Table size="small">
								<TableBody>
									<TableRow>
										<TableCell>Nett Total</TableCell>
										<TableCell align="right">{SouthAfricanRand.format(order.subtotal)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Settlement Amount</TableCell>
										<TableCell align="right">{SouthAfricanRand.format(order.settlementAmount)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Vat Amount</TableCell>
										<TableCell align="right">{SouthAfricanRand.format(order.vat)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Delivery Fee</TableCell>
										<TableCell align="right">{SouthAfricanRand.format(order.logisticsFee)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
										<TableCell align="right" sx={{ fontWeight: "bold" }}>{SouthAfricanRand.format(order.total)}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</InfoSection>
				</Grid>

				{/* Payments */}
				{order?.orderPayments?.length > 0 && (
					<Grid item xs={12}>
						<InfoSection title="Payments" icon={<PaymentIcon color="primary" />}>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell align="right">Amount</TableCell>
											<TableCell align="right">Settlement</TableCell>
											<TableCell>Type</TableCell>
											<TableCell>Status</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{order.orderPayments.map((payment, index) => (
											<TableRow key={index}>
												<TableCell>{moment(payment.dateCreated).format("YYYY-MM-DD | HH:mm:ss")}</TableCell>
												<TableCell align="right">{SouthAfricanRand.format(payment.amount)}</TableCell>
												<TableCell align="right">{SouthAfricanRand.format(payment.settlementAmount)}</TableCell>
												<TableCell>{payment.paymentType?.name}</TableCell>
												<TableCell>{payment.paymentStatus?.name}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</InfoSection>
					</Grid>
				)}

				{/* Sales Orders */}
				{order?.tecfinitySalesOrderDetails?.length > 0 && (
					<Grid item xs={12}>
						<InfoSection title="Sales Orders" icon={<PaymentIcon color="primary" />}>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Account Number</TableCell>
											<TableCell>Ship Via</TableCell>
											<TableCell>Sales Person</TableCell>
											<TableCell>Skus</TableCell>
											<TableCell>Response</TableCell>
											<TableCell>Actions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{order.tecfinitySalesOrderDetails.map((detail, index) => (
											<TableRow key={index}>
												<TableCell>{moment(detail.dateCreated).format("YYYY-MM-DD | HH:mm:ss")}</TableCell>
												<TableCell>{detail.accountNumber}</TableCell>
												<TableCell>{detail.shipVia}</TableCell>
												<TableCell>{detail.salesRep}</TableCell>
												<TableCell>{detail.skuNumber}</TableCell>
												<TableCell>{detail.salesOrderNumber}</TableCell>
												<TableCell>
													{!detail.salesOrderNumber && (
														<IconButton onClick={() => onRetrySaleOrderClick(detail.requestId)} color="primary">
															<RefreshIcon />
														</IconButton>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</InfoSection>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default OrderCreateEditForm;
