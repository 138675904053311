import React, { Suspense } from "react";
import App from "./main-component/App/App";
import "./index.css";
import { createRoot } from "react-dom/client";

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
	<Suspense fallback={<div>Loading...</div>}>
		<React.StrictMode>
			<App/>
		</React.StrictMode>
	</Suspense>
);

