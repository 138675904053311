import { toast } from "react-toastify";
import { getDetailedProduct, getProductsOverview, postUpdateStockInfo, searchBySku } from "../../api/productController";

export const fetchProductsData = async ({
	auth0Token,
	pageSize,
	pageNumber,
	name,
	sku,
	setProducts,
	setTotalResults,
	setPageNumber,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError,
	setErrorMessage
}) => {
	setStatusInProgress();
	try {
		const getProductsDataOptions = {
			auth0Token,
			pageSize: !!pageSize ? Number(pageSize) : 50,
			pageNumber: !!pageNumber ? Number(pageNumber) : 1,
			sku,
			name
		};

		const response = await getProductsOverview(getProductsDataOptions);
		if (!!response?.success) {
			const skip = response.skip;
			setProducts(response.data);
			setTotalResults(response.total);
			setPageNumber(skip + 1);
			setStatusSuccess();
		}
		else {
			setProducts([]);
			setTotalResults(0);
			toast("Could not get products.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			setStatusFailed();
		}

	}
	catch (error) {
		toast("Could not get products.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
		setStatusError();
	}
};

export const searchProductsBySku = async ({
	auth0Token,
	queryString,
	setSearchResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError,
}) => {
	setStatusInProgress();
	try {
		const response = await searchBySku({queryString, auth0Token});

		if (!!response?.success) {
			setSearchResults(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get product.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	} catch (e) {
		toast("Could not search by sku.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
		setStatusError();
	}
};

export const fetchDetailedProductData = async ({
	auth0Token,
	sku,
	setProductData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getDetailedProduct({productSku: sku, auth0Token});

		if (!!response?.success) {
			setProductData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get product.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get product.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const updateStockInfo = async ({
	auth0Token,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postUpdateStockInfo(auth0Token);

		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
