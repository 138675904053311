import React, { Fragment, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApiCallStatus, PromotionsPageView } from "../../utils/constants";
import { cloneDeep } from "lodash";
import {
	deletePromotionData,
	deletePromotionProductData,
	fetchAllPromotionProducts,
	fetchPromotionsData
} from "../PageFunctions/promotionFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import PromotionProducts from "../../components/PromotionProducts";

const PromotionProductsPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const [promotionProducts, setPromotionProducts] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);
	const [promotionProductToDelete, setPromotionProductToDelete] = useState(null);

	const [fetchPromotionProductsDataCallStatus, setFetchPromotionProductsDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [deletePromotionProductCallStatus, setDeletePromotionProductCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);
	const gotoPromotionPage = (promotionId) => navigate(`/promotion/${promotionId}`);


	const handleDeleteClick = (item) => {
		setPromotionProductToDelete(item);
	};

	const handleConfirmDeleteClick = async() => {
		await deletePromotionProductAsync();

	};

	const fetchPromotionProductsDataAsync = async (pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchAllPromotionProducts({
			auth0Token,
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			setPromotionProducts,
			setPageNumber,
			setTotalResults,
			setStatusInProgress: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchPromotionProductsDataCallStatus(ApiCallStatus.Error)
		});
	};

	const deletePromotionProductAsync = async () => {
		if (!promotionProductToDelete?.sku) return;
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await deletePromotionProductData({
			auth0Token,
			sku: promotionProductToDelete.sku,
			setStatusInProgress: () => setDeletePromotionProductCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setDeletePromotionProductCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setDeletePromotionProductCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setDeletePromotionProductCallStatus(ApiCallStatus.Error)

		});
	};

	const authorizedPageLoad = async () => {
		const searchParams = new URLSearchParams(location.search);
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

		await Promise.all([
			fetchPromotionProductsDataAsync(pageNumberParam, pageSizeParam)
		]);

	};

	const onBackClick = () => {
		return navigate(-1);
	};

	const onPageNumberChange = async (_, {activePage}) => {
		await updateUrlQueryValue("pageNumber", activePage);
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const updateUrlQueryValue = async (key, value) => {
		const searchParams = new URLSearchParams(location.search);
		setFetchPromotionProductsDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/promotion-products',
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		scrollTop();

		const searchParams = new URLSearchParams(location.search);

		const pn = searchParams.get("pageNumber");

		if (!!pn) {
			fetchPromotionProductsDataAsync(pn);
		}
	}, [location]);

	const allLoadingStates = [
		fetchPromotionProductsDataCallStatus,
		deletePromotionProductCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;

	return (
		<Fragment>
			<PromotionProducts
				promotionProducts={promotionProducts}
				totalResults={totalResults}
				pageNumber={pageNumber}
				pageSize={pageSize}
				onPageNumberChange={onPageNumberChange}
				onBackClick={onBackClick}
				reloadPage={reloadPage}
				handleDeleteClick={handleDeleteClick}
				handleConfirmDeleteClick={handleConfirmDeleteClick}
				promotionProductToDelete={promotionProductToDelete}
				setPromotionProductToDelete={setPromotionProductToDelete}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PromotionProductsPage;
