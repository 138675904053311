import React, { useState } from "react";
import {
	Box,
	Grid,
	Typography,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Pagination,
	Menu,
	MenuItem, Divider
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from "react-router-dom";
import RetrySalesOrderModal from "../RetrySalesOrderModal";
import UpdateStockInfoModal from "../UpdateStockInfoModal";

const Products = ({
	onBackClick,
	onApplyFiltersClick,
	onClearFiltersClick,
	gotoCreatePage,
	onPageNumberChange,
	onFilterChange,
	pageNumber,
	pageSize,
	totalResults,
	products,
	filters,
	showUpdateStockInfoModal,
	openUpdateStockInfoModal,
	closeModals
}) => {
	const [showFilters, setShowFilters] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleFilterToggle = () => setShowFilters(!showFilters);
	const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
	const handleMenuClose = () => setAnchorEl(null);

	return (
		<Box sx={{ minHeight: '100vh', padding: '2rem', backgroundColor: '#f7f7f7' }}>
			<Typography variant="h5" mb={2}>Product Management</Typography>
			<Divider sx={{ mb: 3 }} />

			<Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
				<Button
					onClick={openUpdateStockInfoModal}
					variant="contained"
					color="primary"
					sx={{ marginLeft: 'auto' }}
					startIcon={<RefreshIcon />}
				>
					Refresh Stock Info
				</Button>
			</Box>

			<Accordion expanded={showFilters} onChange={handleFilterToggle}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6" sx={{ fontWeight: 'bold' }}>Filters</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								name="sku"
								label="SKU"
								variant="outlined"
								value={filters.sku || ''}
								onChange={onFilterChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								name="name"
								label="Name"
								variant="outlined"
								value={filters.name || ''}
								onChange={onFilterChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
								<Button
									onClick={onClearFiltersClick}
									startIcon={<ClearIcon />}
									color="error"
								>
									Clear
								</Button>
								<Button
									onClick={onApplyFiltersClick}
									startIcon={<SearchIcon />}
									variant="contained"
									color="primary"
								>
									Apply
								</Button>
							</Box>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>

			<TableContainer component={Paper} sx={{ mt: 3 }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell><Typography variant="subtitle2" fontWeight="bold">SKU</Typography></TableCell>
							<TableCell><Typography variant="subtitle2" fontWeight="bold">Name</Typography></TableCell>
							<TableCell><Typography variant="subtitle2" fontWeight="bold">Category</Typography></TableCell>
							<TableCell><Typography variant="subtitle2" fontWeight="bold">Type</Typography></TableCell>
							<TableCell><Typography variant="subtitle2" fontWeight="bold">Vendor</Typography></TableCell>
							<TableCell><Typography variant="subtitle2" fontWeight="bold" align="center">Actions</Typography></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{products && products.map((product, index) => (
							<TableRow key={index}>
								<TableCell>
									<Link to={`/product/${product.sku}`} style={{ color: '#1976d2', textDecoration: 'none' }}>
										{product.sku}
									</Link>
								</TableCell>
								<TableCell>{product.name}</TableCell>
								<TableCell>{product.productCategory?.replaceAll("_", " ")}</TableCell>
								<TableCell>{product.productType?.replaceAll("_", " ")}</TableCell>
								<TableCell>{product.vendor}</TableCell>
								<TableCell align="center">
									<IconButton onClick={handleMenuClick}>
										<MoreVertIcon />
									</IconButton>
									<Menu
										anchorEl={anchorEl}
										open={Boolean(anchorEl)}
										onClose={handleMenuClose}
									>
										<MenuItem onClick={() => console.log('Edit Product')}>Edit</MenuItem>
										<MenuItem onClick={() => console.log('Delete Product')}>Delete</MenuItem>
									</Menu>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{totalResults > pageSize && (
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Pagination
						count={Math.ceil(totalResults / pageSize)}
						page={pageNumber}
						onChange={onPageNumberChange}
						color="primary"
					/>
				</Box>
			)}
			{!!showUpdateStockInfoModal &&
				<UpdateStockInfoModal
					open={showUpdateStockInfoModal}
					setClose={closeModals}
				/>
			}
		</Box>
	);
};

export default Products;
