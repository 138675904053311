import axios from "axios";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_WEB_API_URL;

export const getPromotions = async ({pageSize, pageNumber, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const skip = !!pageNumber ? Number(pageNumber) - 1 : 0;
		const params = {
			limit: pageSize,
			skip
		};

		const requestUrl = "";

		return await instance.get(requestUrl, {params: params})
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total,
				                     skip: response.data.skip
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getAllPromotionProducts = async ({pageSize, pageNumber, auth0Token}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});
		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const skip = !!pageNumber ? Number(pageNumber) - 1 : 0;
		const params = {
			limit: pageSize,
			skip
		};

		const requestUrl = "products";

		return await instance.get(requestUrl, {params: params})
		                     .then(response => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data,
				                     total: response.data.total,
				                     skip: response.data.skip
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch(error => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (e) {
		console.error(e);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getPromotion = async ({
	auth0Token,
	promotionId
}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `${promotionId}`;

		return await instance.get(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getPromotionProducts = async ({
	auth0Token,
	promotionId
}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `products/${promotionId}`;

		return await instance.get(requestUrl)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const createPromotion = async ({
	auth0Token,
	name,
	startDate,
	endDate,
	products
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			name,
			startDate,
			endDate,
			products
		};

		const requestUrl = ``;

		return await instance.post(requestUrl, body)
		                     .then((response) => {
			                     if (response.status === 200) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response?.data?.message) {
				                     return {
					                     error: true,
					                     success: false,
					                     message: error.response?.data?.message
				                     };
			                     }
			                     else if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const createPromotionProduct = async ({
	auth0Token,
	promotionId,
	sku,
	retail1,
	retail2,
	retail3,
	retail4,
	retail5,
	retail6,
	retail10
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			sku,
			retail1,
			retail2,
			retail3,
			retail4,
			retail5,
			retail6,
			retail10
		};

		const requestUrl = `product/${promotionId}`;

		return await instance.post(requestUrl, body)
		                     .then((response) => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response?.data?.message) {
									 return {
										 error: true,
										 success: false,
										 message: error.response?.data?.message
									 };
			                     }
			                     else if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
			                     return {
				                     error: true,
				                     success: false,
				                     message: "Something went wrong. Please try again."
			                     };
		                     });
	}
	catch (error) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const updatePromotion = async ({
	auth0Token,
	promotionId,
	name,
	startDate,
	endDate
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			name,
			startDate,
			endDate
		};

		const requestUrl = `${promotionId}`;

		return await instance.put(requestUrl, body)
		                     .then((response) => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const updatePromotionProduct = async ({
	auth0Token,
	promotionId,
	sku,
	retail1,
	retail2,
	retail3,
	retail4,
	retail5,
	retail6,
	retail10,
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			retail1,
			retail2,
			retail3,
			retail4,
			retail5,
			retail6,
			retail10
		};

		const requestUrl = `product/${sku}/${promotionId}`;

		return await instance.put(requestUrl, body)
		                     .then((response) => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const deletePromotion = async ({
	auth0Token,
	promotionId
}) => {
	try {
		const token = "";
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `${promotionId}`;

		return await instance.delete(requestUrl)
		                     .then((response) => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const deletePromotionProduct = async ({
	auth0Token,
	sku,
	promotionId
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/promotion/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `product/${sku}/${promotionId}`;

		return await instance.delete(requestUrl)
		                     .then((response) => {
			                     if (response.status === 204) return {
				                     error: false,
				                     success: true,
				                     message: response.data.message,
				                     data: response.data.data
			                     };
			                     else return {
				                     error: true,
				                     success: false,
				                     message: response.data.message
			                     };
		                     })
		                     .catch((error) => {
			                     if (error.response) {
				                     console.error('Server Error:', error.response.status);
			                     }
			                     else if (error.request) {
				                     console.error('Network Error:', error.request);
			                     }
			                     else {
				                     console.error('Error:', error.message);
			                     }
		                     });
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};
