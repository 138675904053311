import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiCallStatus, FormType } from "../../utils/constants";
import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import PromotionCreateEdit from "../../components/PromotionCreateEdit";
import { createPromotionData } from "../PageFunctions/promotionFunctions";
import PromotionCreate from "../../components/PromotionCreate";

const PromotionCreatePage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [createPromotionDataCallStatus, setCreatePromotionDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoPromotionsPage = () => navigate("/promotions");
	const reloadPage = () => navigate(0);

	const createPromotionDataAsync = async ({
		name,
		startDate,
		endDate,
		products
	}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createPromotionData({
			auth0Token,
			name,
			startDate,
			endDate,
			products,
			setStatusInProgress: () => setCreatePromotionDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCreatePromotionDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCreatePromotionDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreatePromotionDataCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {
		await Promise.all([

		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		if (createPromotionDataCallStatus === ApiCallStatus.Succeeded) {
			gotoPromotionsPage();
		}
	}, [createPromotionDataCallStatus]);

	const onSubmit = async (body) => {

		await createPromotionDataAsync({
			name: body.name,
			startDate: body.startDate,
			endDate: body.endDate,
			products: body.products
		});
	};

	const allLoadingStates = [
		createPromotionDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<PromotionCreate onSubmit={onSubmit} />
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PromotionCreatePage;