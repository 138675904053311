import React, { useEffect, useState } from 'react';
import {
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	IconButton,
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	Divider, CardContent, CardActionArea, Card
} from '@mui/material';
import { Save, ArrowBack, Delete, Edit, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from "moment";

const requiredColumns = ['sku', 'retail1', 'retail2', 'retail3', 'retail4', 'retail5', 'retail6', 'retail10'];
const priceColumns = ['retail1', 'retail2', 'retail3', 'retail4', 'retail5', 'retail6', 'retail10'];

const PromotionEdit = ({
	promotion,
	promotionProducts,
	searchResults,
	clearSearchResults,
	search,
	onBackClick,
	edit,
	editProduct,
	deleteProduct,
	addProduct,
	searchLoading,
}) => {
	const [promotionName, setPromotionName] = useState("");
	const [promotionHandle, setPromotionHandle] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [products, setProducts] = useState([]);
	const [skuSearch, setSkuSearch] = useState('');
	const [showEditModal, setShowEditModal] = useState(false);
	const [productToEdit, setProductToEdit] = useState(null);
	const [editedPrices, setEditedPrices] = useState({});
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [productToDelete, setProductToDelete] = useState(null);
	const navigate = useNavigate();

	const handleBackClick = () => {
		navigate('/promotions');
	};

	const handleSearchChange = (event) => {
		setSkuSearch(event.target.value);
	};

	const handleProductSearch = async () => {
		if (!!skuSearch) {
			await search(skuSearch);
		}
	};

	const handleAddProduct = async (product) => {
		await addProduct(product.sku);
		clearSearchResults();
		setSkuSearch('');
	};

	const openEditModal = (product) => {
		setProductToEdit(product);
		setEditedPrices(product); // Set initial prices to current product prices
		setShowEditModal(true);
	};

	const handlePriceChange = (field, value) => {
		setEditedPrices({ ...editedPrices, [field]: parseFloat(value) });
	};

	const handleSavePrices = async () => {
		await editProduct(productToEdit.sku, editedPrices);
		setEditedPrices({});
		setProductToEdit(null);
		setShowEditModal(false);
	};

	const handleConfirmDelete = (product) => {
		setProductToDelete(product);
		setShowConfirmDelete(true);
	};

	const handleDeleteProduct = async () => {
		await deleteProduct(productToDelete.sku, promotion.promotionId);
		setShowConfirmDelete(false);
		setProductToDelete(null);
	};

	const handleSavePromotion = async () => {
		await edit({ name: promotionName, startDate, endDate });
	};

	useEffect(() => {
		if (!!promotion) {
			setPromotionName(promotion.name);
			setStartDate(moment(promotion.startDate).format("yyyy-MM-DD"));
			setEndDate(moment(promotion.endDate).format("yyyy-MM-DD"));
			setPromotionHandle(promotion.handle);
		}
	}, [promotion]);

	useEffect(() => {
		if (!!promotionProducts) {
			setProducts(promotionProducts);
		}
	}, [promotionProducts]);

	return (
		<Paper elevation={3} sx={{ padding: '24px', maxWidth: 800, margin: 'auto', mt: 4 }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
				<Button color="primary" startIcon={<ArrowBack />} onClick={handleBackClick}>
					Back
				</Button>
				<Typography variant="h6">
					Edit Promotion: <b>{promotion?.name}</b>
				</Typography>
			</Stack>

			<Grid container spacing={2}>
				{/* Promotion Details */}
				<Grid item xs={12}>
					<TextField
						label="Promotion Name"
						variant="outlined"
						fullWidth
						value={promotionName}
						onChange={(e) => setPromotionName(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Handle"
						variant="outlined"
						fullWidth
						value={promotionHandle}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Start Date"
						type="date"
						variant="outlined"
						fullWidth
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="End Date"
						type="date"
						variant="outlined"
						fullWidth
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>

				{/* SKU Search */}
				<Grid item xs={12} sm={8}>
					<TextField
						label="SKU Search"
						variant="outlined"
						type="text"
						value={skuSearch}
						onChange={handleSearchChange}
						size="small"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<Search />}
						onClick={handleProductSearch}
						fullWidth
					>
						Search
					</Button>
				</Grid>

				{/* Search Results with Card Layout */}
				{searchResults && searchResults.length > 0 && (
					<Grid item xs={12}>
						<Box display="flex" flexWrap="wrap" gap={2} mt={2}>
							{searchResults.map((item) => (
								<Card
									key={item.sku}
									sx={{
										width: 250,
										boxShadow: 3,
										borderRadius: 2,
										':hover': { boxShadow: 6 },
									}}
								>
									<CardActionArea onClick={() => handleAddProduct(item)}>
										<CardContent sx={{ display: 'flex', alignItems: 'center' }}>
											<Avatar alt={item.sku} src={item.image} sx={{ mr: 2 }} />
											<Box>
												<Typography variant="subtitle1" fontWeight="bold">
													{item.sku}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{item.description}
												</Typography>
											</Box>
										</CardContent>
									</CardActionArea>
								</Card>
							))}
						</Box>
					</Grid>
				)}

				{/* Product Table */}
				<Grid item xs={12}>
					<TableContainer component={Paper} sx={{ mt: 2 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography variant="body2" fontWeight="bold">
											SKU
										</Typography>
									</TableCell>
									{priceColumns.map((col) => (
										<TableCell key={col}>
											<Typography variant="body2" fontWeight="bold">
												{col.toUpperCase()}
											</Typography>
										</TableCell>
									))}
									<TableCell>
										<Typography variant="body2" fontWeight="bold">
											ACTIONS
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{products.map((product, index) => (
									<TableRow key={index}>
										<TableCell>
											<Typography variant="caption">
												{product.sku}
											</Typography>
										</TableCell>
										{priceColumns.map((col) => (
											<TableCell key={`${index}-${col}`}>
												<Typography variant="caption">
													{product[col]}
												</Typography>
											</TableCell>
										))}
										<TableCell>
											<IconButton color="primary" onClick={() => openEditModal(product)}>
												<Edit size="small" />
											</IconButton>
											<IconButton color="error" onClick={() => handleConfirmDelete(product)}>
												<Delete size="small" />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>

				{/* Save Promotion Button */}
				<Grid item xs={12}>
					<Box textAlign="center" mt={3}>
						<Button variant="contained" color="primary" startIcon={<Save />} onClick={handleSavePromotion}>
							Save Promotion
						</Button>
					</Box>
				</Grid>
			</Grid>

			{/* Edit Prices Modal */}
			<Dialog open={showEditModal} onClose={() => setShowEditModal(false)}>
				<DialogTitle>Edit Prices for {productToEdit?.sku}</DialogTitle>
				<DialogContent>
					{priceColumns.map((col) => (
						<TextField
							key={col}
							label={col.toUpperCase()}
							type="number"
							fullWidth
							margin="dense"
							value={editedPrices[col] || ''}
							onChange={(e) => handlePriceChange(col, e.target.value)}
						/>
					))}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowEditModal(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSavePrices} color="secondary">
						Save
					</Button>
				</DialogActions>
			</Dialog>

			{/* Confirmation Dialog */}
			<Dialog open={showConfirmDelete} onClose={() => setShowConfirmDelete(false)}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					Are you sure you want to remove <b>{productToDelete?.sku}</b>?
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowConfirmDelete(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteProduct} color="secondary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

export default PromotionEdit;
