import { isLoggedIn, removeAuthCookies } from "../../api/authentication";
import { LoggedInStatus } from "../../utils/constants";
import { toast } from "react-toastify";
import { getCountries, getProvinces, getSalesPeople } from "../../api/orderController";
import { getWebsites } from "../../api/websiteController";

export const clearCookies = async () => {
	await removeAuthCookies();
};

export const checkIsLoggedIn = async ({
	setLoggedIn
}) => {
	try {
		const response = await isLoggedIn();
		setLoggedIn(response);
	}
	catch (e) {
		console.error(e);
		setLoggedIn(LoggedInStatus.No);
	}
};

export const clearCookiesAsync = async () => {
	await clearCookies();
};

export const fetchProvinceData = async ({
	auth0Token,
	setProvinces,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getProvinces({auth0Token});

		if (!!response?.success) {
			setProvinces(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get province data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get province data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchCountryData = async ({
	auth0Token,
	setCountries,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getCountries({auth0Token});

		if (!!response?.success) {
			setCountries(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get country data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get country data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchSalesPeopleData = async ({
	auth0Token,
	setSalesPeople,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getSalesPeople({auth0Token});

		if (!!response?.success) {
			setSalesPeople(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get sales people data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get sales people data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchWebsiteData = async ({
	auth0Token,
	setWebsites,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getWebsites({auth0Token});

		if (!!response?.success) {
			setWebsites(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get website data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get website data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};